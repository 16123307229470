import { Component } from '@angular/core';
import { UserDtoModel } from '../models/UserDtoModel';
import { UpdateUserDtoModel } from '../models/UpdateUserDtoModel';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from '../Services/user.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { StorageServiceService } from '../storage.service.service';
import { AuthService } from '../Services/auth.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { NavComponent } from '../compoments/commun/nav/nav.component';
declare var paypal: any;
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [

    MatButtonModule, MatSidenavModule, MatIconModule, MatMenuModule,
    NavComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {
  isLoggedIn = false;

  public roles: string[] = [];
  showAdminBoard = false;
  showDevBoard = false;


  act: any;
  user: UserDtoModel = {

    "username": "",
    "email": "",
    "profileImage": "",
    "name": "",
    "last_name": "",
    "sexe": "",
    "number_phone": ""

  };
  userForm: FormGroup;
  username: string;
  sexe!: string;
  profileImageUrl: string | undefined;
  fileToUpload: File | undefined;

  message: string | null = null;
  error: string | null = null;
  isLoading: boolean = false; // Ajouter isLoading pour gérer le spinner
  currentUser: any;
  updatephoto!: FormGroup;
  x!: string | null;


  constructor(

    private storageService: StorageServiceService,
    private authService: AuthService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private form: FormBuilder,
    private http: HttpClient,
    private translate: TranslateService
  ) {


    // Récupérer le nom d'utilisateur de l'URL
    this.username = this.storageService.getUser().username
    this.userForm = this.formBuilder.group({
      username: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: [''],
      profileImage: [null],// Pour un fichier d'image
      name: ['', Validators.required],
      last_name: ['', Validators.required],
      number_phone: ['', Validators.required],
      sexe: ['', Validators.required],



    });
  }

  finalizePayment(paymentId: string, payerId: string) {
    this.http.post('https://soacwaaslearning.studiolab.fr/elearning/api/paypal/execute', null, {
      params: {
        paymentId: paymentId,
        payerId: payerId
      }
    }).subscribe(response => {
      // Handle response from your backend, e.g., show success message
      console.log('Payment executed successfully:', response);
    }, error => {
      // Handle error, e.g., show error message
      console.error('Payment execution failed:', error);
    });
  }

  ngOnInit(): void {

    this.translate.addLangs(['en', 'fr']);

    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");
      }else{
        this.translate.setDefaultLang('fr');
      }
    } catch (error) {
      this.translate.setDefaultLang('fr');
    }

    this.loadUserDetails();
    this.isLoggedIn = this.storageService.isLoggedIn();

    this.currentUser = this.storageService.getUser();
    this.loadProfileImage();


    if (this.isLoggedIn) {
      const user = this.storageService.getUser();
      this.roles = user.roles;

      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showDevBoard = this.roles.includes('ROLE_DEVELOPER');

      this.username = user.username;


      // Obtenir l'image de profil de l'utilisateur connecté
      if (this.username) {
        this.authService.getProfileImage(this.username).subscribe({
          next: (response) => {
            // Créez une URL d'objet pour le Blob et stockez-la dans profileImageUrl

            this.profileImageUrl = URL.createObjectURL(response);
          },
          error: (err) => {
            console.error('Erreur lors de la récupération de l\'image de profil:', err);
          }
        });
      }
    }


  }

  loadProfileImage(): void {
    const username = this.storageService.getUser().username;
    this.authService.getProfileImage(username).subscribe({
      next: (response) => {
        this.profileImageUrl = URL.createObjectURL(response);
      },
      error: (err) => {
        console.error('Erreur lors de la récupération de l\'image de profil:', err);
      }
    });
  }





  handleFileInput(event: any): void {
    this.fileToUpload = event.target.files[0];
  }
  // Charger les détails de l'utilisateur
  loadUserDetails(): void {
    this.isLoading = true; // Commencer le chargement
    this.userService.getUserDetails(this.username).subscribe(
      (userDetails: any) => {
        let name = document.getElementById("userInputName") as HTMLInputElement
        let last_name = document.getElementById("userInputlast") as HTMLInputElement
        let email = document.getElementById("userInputemail") as HTMLInputElement
        let number_phone = document.getElementById("userInputphone") as HTMLInputElement
        let sexe = document.getElementById("userInputsexe") as HTMLInputElement

        name.value = userDetails.name
        last_name.value = userDetails.last_name
        email.value = userDetails.email
        number_phone.value = userDetails.number_phone
        if(userDetails.sexe){
          sexe.value = userDetails.sexe

        }
        this.isLoading = false; // Arrêter le chargement
        this.user = userDetails;
        // this.populateForm(userDetails);
      },
      error => {
        this.isLoading = false; // Arrêter le chargement
        this.error = 'Failed to load user details';
        console.error('Error loading user details:', error);
      }
    );
  }

  changeLanguage=(lang:string)=>{
    this.translate.setDefaultLang(lang)
  }

  // Peupler le formulaire avec les détails de l'utilisateur
  populateForm(userDetails: UserDtoModel): void {
    this.userForm.setValue({
      username: userDetails.username,
      name: userDetails.name,
      email: userDetails.email,
      last_name: userDetails.last_name,

      password: '', // Laissez vide car nous ne récupérons pas le mot de passe
      profileImage: null // Gérez l'image de profil séparément si nécessaire
    });
  }

  // Mettre à jour les détails de l'utilisateur
  updateUserDetails(): void {
    const updateUserDTO: UpdateUserDtoModel = {
      username: this.userForm.value.username,
      email: this.userForm.value.email,
      name: this.userForm.value.name,
      sexe: this.userForm.value.sexe,
      number_phone: this.userForm.value.number_phone,
      last_name: this.userForm.value.last_name,
      password: this.userForm.value.password,
      profileImageFile: this.userForm.value.profileImage // Gérer le fichier image s'il est mis à jour
    };

    this.userService.updateUserDetails(this.username, updateUserDTO).subscribe(
      response => {
        this.message = 'User details updated successfully!';
        // Actualisez les détails de l'utilisateur
        this.loadUserDetails();
      },
      error => {

        console.error('Error updating user details:', error);
      }
    );
  }

  updateProfileImage(): void {
    if (!this.fileToUpload) {
      console.error('Aucun fichier sélectionné.');
      return;
    }

    // Crée un FormData et ajoute le fichier à envoyer au backend
    const formData = new FormData();
    formData.append('file', this.fileToUpload);

    // Récupère l'ID de l'utilisateur actuel
    const userId = this.storageService.getUser().id;

    // Appelle le service pour mettre à jour l'image de profil
    this.userService.updateImage(this.fileToUpload, userId).subscribe({
      next: (data) => {
        // Mise à jour réussie : recharge l'image de profil
        this.loadProfileImage();
      },
      error: (err) => {
        console.error('Erreur lors de la mise à jour de l\'image de profil:', err);
      }
    });
  }

}
