import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { MatChipsModule } from '@angular/material/chips';
import Swal from 'sweetalert2';
import { TranslateModule, TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-updateassistant',
  standalone: true,
  imports: [
    MatButtonModule,
    MatProgressBarModule,
    HttpClientModule,
    AngularEditorModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatIconModule,
    MatChipsModule,
    CdkDropList,
    CdkDrag,
    TranslateModule
  ],
  templateUrl: './updateassistant.component.html',
  styleUrls: ['./updateassistant.component.scss']
})
export class UpdateassistantComponent implements OnInit {
  id: string = "";
  name: string = "";
  username: string = "";
  email: string = "";
  last_name: string = "";
  sexe: string = "";
  loading: boolean = false;
  isSuccessful: boolean = false;
  isUpdateFailed: boolean = false;
  errorMessage: string = '';

  constructor(
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.translate.addLangs(['en', 'fr']);

    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");
      }else{
        this.translate.setDefaultLang('fr');
      }
    } catch (error) {
      this.translate.setDefaultLang('fr');
    }
    const apiUrl = `https://soacwaaslearning.studiolab.fr/elearning/api/users/getUser/${this.route.snapshot.paramMap.get('id')}`;

    this.http.get(apiUrl).subscribe((result: any) => {
      this.id = result.id;
      this.name = result.name;
      this.username = result.username;
      this.email = result.email;
      this.last_name = result.last_name;
      this.sexe = result.sexe;
    });
  }

  createCourse() {
    this.loading = true;
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/api/users/update";
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  
    let data1 = {
      id: this.id,
      name: this.name,
      username: this.username,
      email: this.email,
      last_name: this.last_name,
      sexe: this.sexe
    };
  
    this.http.put(apiUrl, data1, { headers }).subscribe({
      next: result => {
        this.loading = false;
        this.isSuccessful = true;
        this.isUpdateFailed = false;
  
        this.translate.get([
          'update_success_title',
          'update_success_message'
        ]).subscribe(translations => {
          Swal.fire({
            title: translations['update_success_title'],
            text: translations['update_success_message'],
            icon: 'success'
          });
        });
  
        this.router.navigate(['/dashboard-admin/assistant']);
      },
      error: err => {
        this.loading = false;
        this.translate.get([
          'update_failure_title',
          'update_failure_message'
        ]).subscribe(translations => {
          Swal.fire({
            title: translations['update_failure_title'],
            text: translations['update_failure_message'],
            icon: 'error'
          });
        });
  
        this.errorMessage = err.error?.message || 'An error occurred during update.';
        this.isUpdateFailed = true;
      }
    });
  }
  
  changeLanguage=(lang:string)=>{
    this.translate.setDefaultLang(lang)
  }

}
