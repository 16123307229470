import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ItemsComponent } from '../../cources/items/items.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-recommendations',
  standalone: true,
  imports: [ItemsComponent, TranslateModule],
  templateUrl: './recommendations.component.html',
  styleUrl: './recommendations.component.scss'
})
export class RecommendationsComponent implements OnInit {

  category = ""
  cources: any[] = []
  constructor(private translate: TranslateService, private http: HttpClient) {
    translate.addLangs(['en', 'fr']);

    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.search()

  }


  search() {
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/course/getRecommendation"
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let params = new HttpParams().set('category', this.category)
    this.http.get(apiUrl, { params, headers }).subscribe((result: any) => {
      this.cources = result.content

    })
  }
  changeCatergory(e: any) {

    this.category = e.target.value
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/course/getRecommendation"
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let params = new HttpParams().set('category', e.target.value)
    this.http.get(apiUrl, { params, headers }).subscribe((result: any) => {
      this.cources = result.content

    })
  }

}
