<div class="leason-body">
    <div class="row">
        <a class="col-8" href="dashboard-admin/cources" style="padding-bottom: 20px; text-decoration: none;">
            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.5 7.99992H17.5M8.33333 3.33325L2.5 7.99992L8.33333 3.33325ZM2.5 7.99992L8.33333 12.6666L2.5 7.99992Z"
                    stroke="#282938" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
            {{ 'HOMEPAGE' | translate }}
        </a>
    </div>
    @if(showleasson){
    <h2>
        {{currentLeassonBody?.chapter}}
    </h2>
    <h1>
        {{currentLeassonBody?.title}}
    </h1>

    @if(showButtons){
    <div class="d-flex p-md-4 justify-content-between">
        <button (click)="fnShowPdf()">
            {{ 'LESSON.SHOW_PDF' | translate }}
        </button>
        <button (click)="fnShowVideo()">
            {{ 'LESSON.SHOW_VIDEO' | translate }}
        </button>
    </div>
    }


    @if(currentLeassonBody?.videoUrl){
    @if(showvideo){
    <video width="100%" [src]="currentLeassonBody?.videoUrl" controls>

        {{ 'LESSON.VIDEO_NOT_SUPPORTED' | translate }}
    </video>
    }
    }
    @if(currentLeassonBody?.pdfUrl){
    @if(showpdf){
    <pdf-viewer [src]="currentLeassonBody?.pdfUrl" [render-text]="true" style="display: block;" [show-all]="true"
        [zoom]="zoom">
    </pdf-viewer>
    }

    }


    <div class="d-flex justify-content-between pt-md-4 pb-md-4 ">
        <button (click)="previousPage()" [disabled]="firstLeasson">
            {{ 'LESSON.RETURN' | translate }}
        </button>

        @if(lastLeasson){
        <button (click)="navigateToAbout()">
            {{ 'LESSON.FINISH_CONTINUE' | translate }}
        </button>
        } @else {
        <button (click)="nextPage()">        {{ 'LESSON.NEXT' | translate }}</button>
        }

    </div>
    } @else {

    <p>        {{ 'LESSON.NO_CONTENT' | translate }}</p>

    }


<p>

    
</p>

</div>