import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-description',
  standalone: true,
  imports: [

    TranslateModule,
    MatButtonModule, MatMenuModule, MatIconModule
  ],
  templateUrl: './description.component.html',
  styleUrl: './description.component.scss'
})
export class DescriptionComponent {

  @Input() name:string=""
  @Input() category:string=""
  @Input() videoUrl:string=""
  @Input() briefing:string=""
  @Input() picture:string=""


  constructor( private translate: TranslateService) {
 
    translate.addLangs(['en', 'fr']);

    translate.setDefaultLang('en');
 
  }

  changeLanguage=(lang:string)=>{
    this.translate.setDefaultLang(lang)
  }

}
