import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule, TranslateService, LangChangeEvent, TranslationChangeEvent, DefaultLangChangeEvent } from '@ngx-translate/core';

export interface Faq {
  id: number;
  questionFr: string;
  answerFr: string;
  questionEn: string;
  answerEn: string;
}
@Component({
  selector: 'app-general-faq',
  standalone: true,
  imports: [MatExpansionModule, TranslateModule],
  templateUrl: './general-faq.component.html',
  styleUrl: './general-faq.component.scss'
})
export class GeneralFaqComponent implements OnInit {
  constructor(private translate: TranslateService, private http: HttpClient,) { }
  questions: Faq[] = []
  showfrench = true
  showenglish = false


  ngOnInit(): void {


    this.translate.addLangs(['en', 'fr']);

    try {
      if (localStorage.getItem("lang")) {
        this.translate.setDefaultLang(localStorage.getItem("lang") || "");
      } else {
        this.translate.setDefaultLang('fr');
      }
    } catch (error) {
      this.translate.setDefaultLang('fr');
    }

    const apiUrl1 = `https://soacwaaslearning.studiolab.fr/elearning/generalfaq/getAll`

    this.http.get(apiUrl1).subscribe((result: any) => {
      this.questions = result;
    })

    this.translate.onDefaultLangChange.subscribe((event: DefaultLangChangeEvent) => {
      console.log(event.lang);
      if (event.lang == "en") {
        this.showfrench = false
        this.showenglish = true
      } else {
        this.showfrench = true
        this.showenglish = false
      }

    });


  }
}
