<app-nav></app-nav>
<div class="payement-body container">
    <div class="row">
        <div class="col-md-5">
            <app-methos></app-methos>
            <app-payments-infos></app-payments-infos>
        </div>
        <div class="col-md-7">
            <app-payment-form [cource]="cource"  ></app-payment-form>
        </div>
    </div>
</div>