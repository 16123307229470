import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { StorageServiceService } from '../../storage.service.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../Services/user.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-attestation',
  templateUrl: './attestation.component.html',
  styleUrls: ['./attestation.component.scss'],
  standalone: true,
  imports: [HttpClientModule, CommonModule],


})
export class AttestationComponent implements OnInit {


  cource: any

  giveDate: Date = new Date()

  user: any

  today: Date = new Date()
  dd = ""
  mm = ""
  yyyy = ""


  ngOnInit(): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.storageService.getUser().tokenType} ${this.storageService.getUser().accessToken}`
    });
    const apiUrl = ` https://soacwaaslearning.studiolab.fr/elearning/course/getCource/${this.route.snapshot.paramMap.get('id')}`
    const apiUrl1 = ` https://soacwaaslearning.studiolab.fr/elearning/course/getCourceByStudent/${this.route.snapshot.paramMap.get('id')}`
    this.http.get(apiUrl, { headers }).subscribe((resulte: any) => {

      this.cource = resulte
      this.dd = new Date(resulte.createdAt).getDate() + ""
      this.mm = new Date(resulte.createdAt).getMonth() + 1 + ""
      this.yyyy = new Date(resulte.createdAt).getFullYear() + ""

    })
      this.http.get(apiUrl1, { headers }).subscribe((resulte: any) => {

      this.dd = new Date(resulte.createdAt).getDate() + ""
      this.mm = new Date(resulte.createdAt).getMonth() + 1 + ""
      this.yyyy = new Date(resulte.createdAt).getFullYear() + ""

    })

    this.userService.getUserDetails(this.storageService.getUser().username).subscribe(data => {
      console.log(data);
      this.user = data

    })



  }

  constructor(private userService: UserService,
    private route: ActivatedRoute, private http: HttpClient, private storageService: StorageServiceService) { }

  downloadPDF(): void {
    // Get the div with the ID "test"
    let t = document.getElementById("test") as HTMLElement
    t.style.rotate = "90deg"
    window.print();
    setTimeout(() => {
      t.style.rotate = "0deg"

    }, 500);
    // const attestationDiv = document.getElementById('test');
    // if (attestationDiv) {
    //   // Use html2canvas to capture the content of the div as an image
    //   html2canvas(attestationDiv).then((canvas) => {
    //     // Convert the canvas to a data URL
    //     const imgData = canvas.toDataURL('image/png');
    //     // Calculate dimensions for A4 size PDF
    //     const pdfWidth = 260;
    //     const pdfHeight = (canvas.height * 550) / canvas.width;
    //     // Create a new instance of jsPDF with A4 dimensions
    //     const doc = new jsPDF('p', 'mm', 'a4');
    //     // Add the captured content as an image to the PDF
    //     doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //     // Save the PDF
    //     doc.save('attestation.pdf');
    //   });
    // } else {
    //   console.error("La div avec l'ID 'test' n'a pas été trouvée.");
    // }
  }
}
