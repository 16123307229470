<div class="container p-md-5">



    <a href="" style="padding-bottom: 20px;">
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 7.99992H17.5M8.33333 3.33325L2.5 7.99992L8.33333 3.33325ZM2.5 7.99992L8.33333 12.6666L2.5 7.99992Z"
                stroke="#282938" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        {{ 'HOMEPAGE' | translate }}
    </a>

    <div class="language-switcher">
        <span class="d-flex">
            <div class="d-flex px-md-5">
                <button mat-icon-button [matMenuTriggerFor]="menulang">
                    <img style="width: 30px; height: 30px;" [src]="getFlag()" alt="{{'AVATAR' | translate}}">
                </button>
                <button mat-icon-button [matMenuTriggerFor]="menulang">
                    <mat-icon>expand_more</mat-icon>
                </button>
            </div>

            <mat-menu #menulang="matMenu">
                <button mat-menu-item (click)="changeLanguage(getDiffLangAp())">
                    <span><img style="width: 35px; height: 35px;" [src]="getDiffFlag()"> {{getDiffLang()}}</span>
                </button>
            </mat-menu>
        </span>
    </div>


    <main class="row">
        <div class="col-md-6">
            <div>
                <h1>
                    <!-- {{ 'ONE_STEP_CLOSER' | translate }} -->
                </h1>
                <p>
                    {{ 'E_LEARNING_SERVICE' | translate }}
                </p>
            </div>
            <img src="assets/homme-affaires-travailler-ordinateur-portable_23-2148634789.jpg_t=st=1713784481~exp=1713788081~hmac=32f0f21f8750d6a69012bba30b31512dea2cc77d596e3e819e5530f4d730fa47&w=740.png"  class="img-fluid">
        </div>
        <div class="col-md-6">
            <div class="content-page">
                <h2>
                    {{ 'REGISTER' | translate }}
                </h2>
                <p>
                    <!-- {{ 'PREPARE_FUTURE' | translate }} -->
                </p>


                <form *ngIf="!isSuccessful" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm">
                    <div class="mb-3">
                        <input type="text" class="form-control custom-input" name="username" #username="ngModel" placeholder="{{ 'YOUR_NAME' | translate }}" [(ngModel)]="form.username">
                    </div>
                    <div class="mb-3">
                        <input type="email" class="form-control custom-input" #email="ngModel" name="email" placeholder="{{ 'EMAIL' | translate }}" [(ngModel)]="form.email">
                    </div>
                    <div class="mb-3">
                        <input type="password" class="form-control custom-input" name="password" #password="ngModel" placeholder="{{ 'PASSWORD' | translate }}" [(ngModel)]="form.password">
                    </div>
                    <div class="mb-3">
                        <label class="form-label text-dark" for="profileImage">{{ 'PICTURE' | translate }}</label>
                        <input type="file" id="profileImage" name="profileImage" class="form-control" (change)="onProfileImageFileChange($event)">
                    </div>
                    <div class="text-center">
                        <button class="btn btn-primary">
                            {{ 'REGISTER_BUTTON' | translate }}
                        </button>
                    </div>
                </form>
                <a href="/login">
                    {{ 'LOGIN_PROMPT' | translate }}
                </a>



            </div>
        </div>
    </main>
</div>
