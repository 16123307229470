<div class="container">
    <h2>Add New Benefit</h2>
    <form [formGroup]="benefitForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="titre">Title:</label>
        <input type="text" formControlName="titre" class="form-control" id="titre">
      </div>
      <div class="form-group">
        <label for="description">Description:</label>
        <textarea formControlName="description" class="form-control" id="description"></textarea>
      </div>
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>
  