<mat-drawer-container  style="width: 100%; height: 100%;" autosize>
    <mat-drawer #drawer mode="side">
        <app-sidebar-student></app-sidebar-student>
    </mat-drawer>

    <div >
        <app-navbar-leasson (sidenav)="drawer.toggle()"></app-navbar-leasson>
        <router-outlet></router-outlet>

    </div>
</mat-drawer-container>