import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { NewPasswordComponent } from './pages/new-password/new-password.component';
import { PasswordResetSeccessComponent } from './pages/password-reset-seccess/password-reset-seccess.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ForgotPasswordSuccessComponent } from './pages/forgot-password-success/forgot-password-success.component';
import { CourcesComponent } from './pages/cources/cources.component';
import { DetailCourseComponent } from './pages/detail-course/detail-course.component';
import { ExamComponent } from './pages/exam/exam.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { LeassonsComponent } from './pages/leassons/leassons.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { AttestationComponent } from './pages/attestation/attestation.component';
import { DashboardStudentComponent } from './pages/dashboard-student/dashboard-student.component';
import { DashboradStudentCourcesComponent } from './pages/dashborad-student-cources/dashborad-student-cources.component';
import { NgModule } from '@angular/core';
import { ProfileComponent } from './profile/profile.component';
import { EditorComponent } from './compoments/commun/editor/editor.component';
import { VideoUploadComponent } from './compoments/commun/video-upload/video-upload.component';
import { UpdateCourceComponent } from './pages/cources/update-cource/update-cource.component';
import { UpdateLeassonComponent } from './pages/leassons/update-leasson/update-leasson.component';
import { EditExamComponent } from './pages/cources/edit-exam/edit-exam.component';
import { DashboardAdminComponent } from './pages/dashboard-admin/dashboard-admin.component';
import { CourcesTableComponent } from './compoments/dashboard-admin/cources-table/cources-table.component';
import { AssistantesComponent } from './assistantes/assistantes.component';
import { CreateAssitantComponent } from './create-assitant/create-assitant.component';
import { UpdateassistantComponent } from './updateassistant/updateassistant.component';
import { AdminStatisticsComponent } from './compoments/dashboard-admin/admin-statistics/admin-statistics.component';
import { GuardGuard } from './auth.guard';
import { BenefitssComponent } from './benefitss/benefitss.component';
import { AddComponent } from './benefitss/add/add.component';
import { UpdateComponent } from './benefitss/update/update.component';
import { StudentsChartsComponent } from './compoments/dashboard-student/students-charts/students-charts.component';
import { CoursesProgressComponent } from './compoments/dashboard-student/courses-progress/courses-progress.component';
import { EditFaqComponent } from './pages/cources/edit-faq/edit-faq.component';
import { EditGeneralFaqComponent } from './pages/edit-general-faq/edit-general-faq.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'new-password', component: NewPasswordComponent },
  { path: 'password-reset-seccess', component: PasswordResetSeccessComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'forgot-password-success', component: ForgotPasswordSuccessComponent },
  { path: 'cources', component: CourcesComponent },
  { path: 'cources/detail/:id', component: DetailCourseComponent },
  { path: 'cources/exam/:id', component: ExamComponent,canActivate: [GuardGuard] },
  { path: 'cources/pay/:id', component: PaymentComponent,canActivate: [GuardGuard] },
  { path: 'learning/course/:idc', component: LeassonsComponent ,canActivate: [GuardGuard] },
  { path: 'attestation/:id', component: AttestationComponent,canActivate: [GuardGuard] },
  // { path: 'dashboard-student', component: DashboardStudentComponent,canActivate: [GuardGuard] },
  // { path: 'dashboard-student-course', component: DashboradStudentCourcesComponent,canActivate: [GuardGuard] },
  { path: 'profile', component: ProfileComponent,canActivate: [GuardGuard] },
  {
    path: 'dashboard-student',
    component: DashboardStudentComponent,
    children: [
      { path: '', component: StudentsChartsComponent },
      { path: 'cources', component: CoursesProgressComponent }
    ]
  },

  {
    path: 'dashboard-admin',
    component: DashboardAdminComponent,
    children: [
      { path: '', component: AdminStatisticsComponent ,canActivate: [GuardGuard] },
      { path: 'cources', component: CourcesTableComponent,canActivate: [GuardGuard]  },
      { path: 'create-cource', component: EditorComponent ,canActivate: [GuardGuard] },
      { path: 'leasson-update/:id', component: UpdateLeassonComponent ,canActivate: [GuardGuard] },
      { path: 'cource-update/:id', component: UpdateCourceComponent,canActivate: [GuardGuard]  },
      { path: 'create-leasson/:id', component: VideoUploadComponent ,canActivate: [GuardGuard] },
      { path: 'edit-exam/:id', component: EditExamComponent ,canActivate: [GuardGuard] },
      { path: 'edit-faq/:id', component: EditFaqComponent ,canActivate: [GuardGuard] },
      { path: 'assistant', component: AssistantesComponent ,canActivate: [GuardGuard] },
      { path: 'create-assitant', component: CreateAssitantComponent ,canActivate: [GuardGuard] },
      { path: 'edit-assistant/:id', component: UpdateassistantComponent,canActivate: [GuardGuard]  },
      { path: 'benf', component: BenefitssComponent,canActivate: [GuardGuard] },
      { path: 'add', component: AddComponent,canActivate: [GuardGuard] },
      { path: 'updt', component: UpdateComponent,canActivate: [GuardGuard] },
      { path: 'generalfaq', component: EditGeneralFaqComponent,canActivate: [GuardGuard] },

    ]
  },



  { path: '**', component: NotFoundPageComponent },


];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
