import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { StorageServiceService } from '../../../storage.service.service';
@Component({
  selector: 'app-students-charts',
  standalone: true,
  imports: [NgxChartsModule],
  templateUrl: './students-charts.component.html',
  styleUrl: './students-charts.component.scss'
})
export class StudentsChartsComponent implements OnInit {
  constructor( private http: HttpClient, private storageService: StorageServiceService){}

  data: any[]= [
    { name: 'Category A', value: 30 },
    { name: 'Category B', value: 80 },
    { name: 'Category C', value: 45 },
    { name: 'Category D', value: 20 }
  ]
  data1: any[]= [
    { name: 'Category A', value: 30 },
    { name: 'Category B', value: 80 },
    { name: 'Category C', value: 45 },
    { name: 'Category D', value: 20 }
  ]
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Category';
  showYAxisLabel = true;
  yAxisLabel = 'Videos Consumed';
  colorScheme = 'vivid';
  showLabels = true;
  isDoughnut = false;


  ngOnInit(): void {
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/course/getCourceFromStudent"
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.storageService.getUser().tokenType} ${this.storageService.getUser().accessToken}`
    });

    this.http.get(apiUrl,  { headers }).subscribe((result:any) => {
      console.log("app-students-charts ::",result)
        this.data1=result.map((el:any)=>{
          return{
            name: el.courceName,
            value: el.leassonsIds.length
          }
        })
        this.data=result.map((el:any)=>{
          return{
            name: el.courceName,
            value: el.progress
          }
        })
    })


  }
}
