<app-nav></app-nav>
<div class="main-content">
    <div class="container">
        <div class="row">
            <!-- Left column for profile information -->
            <div class="col-md-8">
                <div class="card">
                    <div class="header">

                        <h4 class="title">{{ 'EDIT_PROFILE' | translate }}</h4>
                    </div>
                    @if(message){
                        <div class="alert alert-success">
                            {{ message }}
                        </div>
                    }

                    @if(user){
                        <div class="content" >
                            <form [formGroup]="userForm" (ngSubmit)="updateUserDetails()">
                                <div class="form-group">
                                    <label>{{ 'USERNAME' | translate }}</label>
                                    <input readonly formControlName="username" type="text" class="form-control" placeholder="{{ 'USERNAME' | translate }}" [(ngModel)]="user.username" name="username">
                                </div>
                                <div class="form-group">
                                    <label>{{ 'EMAIL_ADDRESS' | translate }}</label>
                                    <input readonly formControlName="email" id="userInputemail" type="text" class="form-control" placeholder="{{ 'EMAIL_ADDRESS' | translate }}" [(ngModel)]="user.email" name="email">
                                </div>
                                <div class="form-group">
                                    <label>{{ 'FIRST_NAME' | translate }}</label>
                                    <input id="userInputName" class="form-control" formControlName="name" [(ngModel)]="user.name" type="text" placeholder="{{ 'FIRST_NAME' | translate }}">
                                </div>
                                <div class="form-group">
                                    <label>{{ 'LAST_NAME' | translate }}</label>
                                    <input id="userInputlast" class="form-control" formControlName="last_name" [(ngModel)]="user.last_name" type="text" placeholder="{{ 'LAST_NAME' | translate }}">
                                </div>
                                <div class="form-group">
                                    <label>{{ 'PHONE_NUMBER' | translate }}</label>
                                    <input id="userInputphone" [(ngModel)]="user.number_phone" class="form-control" formControlName="number_phone" type="text" placeholder="{{ 'PHONE_NUMBER' | translate }}">
                                </div>
                                <div class="form-group">
                                    <label>{{ 'GENDER' | translate }}</label>
                                    <select class="form-control" formControlName="sexe" id="userInputsexe" >
                                        <option  disabled selected>{{ 'SELECT_GENDER' | translate }}</option>
                                        <option value="male">{{ 'MALE' | translate }}</option>
                                        <option value="female">{{ 'FEMALE' | translate }}</option>
                                    </select>
                                </div>
                                <button type="submit" >{{ 'UPDATE_PROFILE' | translate }}</button>
                                <div class="clearfix"></div>
                            </form>
                        </div>
                    }

                </div>
            </div>
            <!-- Right column for profile image and details -->
            <div class="col-md-4">
                <div class="card card-user">
                    <!-- <div class="image">
                        <img src="https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=400" alt="{{ 'BACKGROUND_IMAGE' | translate }}">
                    </div> -->
                    <div class="content">
                        <div class="author">
                            <div class="profile-image-section">

                                @if(profileImageUrl){
                                    <img [src]="profileImageUrl" class="avatar" alt="{{ 'PROFILE_IMAGE' | translate }}">

                                }
                                <input type="file" class="upload-input" (change)="handleFileInput($event)">
                                <button class="update-button btn btn-secondary" (click)="updateProfileImage()">{{ 'UPDATE_PROFILE_PICTURE' | translate }}</button>
                            </div>
                            <h4 class="title">{{ user.username }}</h4>
                            <h5 class="subtitle">{{ user.email }}</h5>
                        </div>
                        <p class="description text-center">{{ roles }}</p>
                    </div>
                    <hr>

                </div>
            </div>
        </div>
    </div>
</div>
