import { HttpClient, HttpClientModule, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatChipsModule } from '@angular/material/chips';

import { AngularEditorModule } from '@kolkov/angular-editor';

import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export interface Lesson {
  id: number;
  title: string;
  chapter: string;
  indexOfLeasson: number;
}

@Component({
  selector: 'app-update-cource',
  standalone: true,
  imports: [MatProgressSpinnerModule, TranslateModule, MatButtonModule, MatProgressBarModule, HttpClientModule, AngularEditorModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatTabsModule, MatIconModule, MatChipsModule, CdkDropList, CdkDrag],
  templateUrl: './update-cource.component.html',
  styleUrl: './update-cource.component.scss'
})
export class UpdateCourceComponent implements OnInit {
  isloading = false

  removeChapter(cp: string) {
    this.chapters.splice(this.chapters.indexOf(cp), 1)
  }
  ngOnInit(): void {
    this.translate.addLangs(['en', 'fr']);

    try {
      if (localStorage.getItem("lang")) {
        this.translate.setDefaultLang(localStorage.getItem("lang") || "");
      } else {
        this.translate.setDefaultLang('fr');
      }
    } catch (error) {
      this.translate.setDefaultLang('fr');
    }
    const apiUrl = ` https://soacwaaslearning.studiolab.fr/elearning/course/getCource/${this.route.snapshot.paramMap.get('id')}`
    const apiUrl1 = ` https://soacwaaslearning.studiolab.fr/elearning/leasson/getLeassonsByCourse/${this.route.snapshot.paramMap.get('id')}`

    this.http.get(apiUrl).subscribe((result: any) => {
      this.id = result.id
      this.name = result.name
      this.picture = result.picture
      this.description = result.description
      this.instructor = result.instructor
      this.syllabus = result.syllabus
      this.precondition = result.precondition
      this.teachingMethod = result.teachingMethod
      this.testimonials = result.testimonials
      this.category = result.category
      this.chapters = result.chapters
      this.price = result.price
      this.passingScore = result.passingScore
      this.briefing = result.briefing
      this.category = result.category
      this.videoUrl = result.videoUrl
      this.fourmateur1 = result.fourmateur1
      this.fourmateur2 = result.fourmateur2
      this.formationIdentifier = result.formationIdentifier
      this.somedate = result.somedate
      this.somedate2 = result.somedate2
      this.apprentissage = result.apprentissage
      this.mode = result.mode

    })

    this.http.get(apiUrl1).subscribe((result: any) => {

      this.leassons = result
        .sort((a: Lesson, b: Lesson) => a.indexOfLeasson - b.indexOfLeasson)
        .map((el: any, i: number) => {
          return { ...el, indexOfLeasson: i }
        })
    })
  }


  selectedFile: File | null = null;
  progress: number = 0;
  videoUrl: string = '';

  id: string = ""
  name = ""
  picture = ""
  description = ""
  instructor = ""
  syllabus = ""
  precondition = ""
  teachingMethod = ""
  testimonials = ""
  briefing = ""
  fourmateur1 = ""
  fourmateur2 = ""
  formationIdentifier = ""
  somedate = ""
  somedate2 = ""
  apprentissage = ""
  mode = ""

  category = ""

  newChapter = ""

  chapters: string[] = []

  leassons: Lesson[] = []

  price = 0
  passingScore = 0


  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(private translate: TranslateService, private _snackBar: MatSnackBar, private http: HttpClient, private route: ActivatedRoute, private router: Router) {

  }

  createCourse() {
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/course/update"
    const apiUrl1 = "https://soacwaaslearning.studiolab.fr/elearning/leasson/updateLeassonsOrder"
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let data1 = {
      id: this.id,
      name: this.name,
      description: this.description,
      briefing: this.briefing,
      instructor: this.instructor,
      syllabus: this.syllabus,
      precondition: this.precondition,
      teachingMethod: this.teachingMethod,
      testimonials: this.testimonials,
      category: this.category,
      price: this.price,
      chapters: this.chapters,
      picture: this.picture,
      passingScore: this.passingScore,
      fourmateur1: this.fourmateur1,
      fourmateur2: this.fourmateur2,
      formationIdentifier: this.formationIdentifier,
      somedate: this.somedate,
      somedate2: this.somedate2,
      apprentissage: this.apprentissage,
      mode: this.mode,
      videoUrl : this.videoUrl

    }
    this.http.put(apiUrl, data1, { headers }).subscribe(result => {

      const successMessage = this.translate.instant('course_updated_successfully'); // Utilisation de la clé de traduction
      this.openSnackBar(successMessage);
      setTimeout(() => {
        this.router.navigate(['dashboard-admin/cources']);
      }, 2000);
    })
    this.http.put(apiUrl1, this.leassons, { headers }).subscribe(result => {

    })
  }

  addChapters() {
    this.chapters.push(this.newChapter)
    this.newChapter = ""
  }

  updateTask(id: number) {
    this.router.navigate([`dashboard-admin/leasson-update/${id}`]);
  }

  uploadImage(e: any) {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    this.http.post('https://soacwaaslearning.studiolab.fr/elearning/api/images/upload', formData, {
      reportProgress: true,
      observe: 'events'
    })
      .subscribe((event: any) => {


        this.picture = `https://soacwaaslearning.studiolab.fr/elearning/api/images/view/${event.body.message}`;
      }, error => {
        console.error('Error in POST request:', error);
      });
  }



  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.leassons, event.previousIndex, event.currentIndex);
    this.leassons = this.leassons.map((el: any, i: number) => {
      return { ...el, indexOfLeasson: i }
    })

  }


  onFileSelected(event: Event): void { // Explicitly type the event parameter
    const input = event.target as HTMLInputElement;
    if (input && input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
      const videoURL = URL.createObjectURL(this.selectedFile);

      // Create a video element
      const videoElement = document.createElement('video');
      videoElement.src = videoURL;

      // Listen for the loadedmetadata event to get the video duration
      videoElement.addEventListener('loadedmetadata', () => {


        // Clean up the object URL
        URL.revokeObjectURL(videoURL);
      });

    } else {
      this.selectedFile = null;
    }
  }

  changeLanguage = (lang: string) => {
    this.translate.setDefaultLang(lang)
  }

  onUpload(): void {
    this.videoUrl=""

    if (this.selectedFile) { // Type guard to check if selectedFile is not null
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);
      this.isloading = true
      this.http.post('https://soacwaaslearning.studiolab.fr/elearning/api/videos/upload', formData, {
        reportProgress: true,
        observe: 'events'
      })
        .subscribe((event: any) => {

          if (event.type === HttpEventType.UploadProgress && event.total) {
            this.progress = Math.round((event.loaded / event.total) * 100);
          } else if (event.type === HttpEventType.Response) {
            this.isloading = false

            this.videoUrl = `https://soacwaaslearning.studiolab.fr/elearning/api/videos/stream/${event["body"]["name"]}`;
          }
        }, error => {
          console.error('Error in POST request:', error);
        });
    } else {
      console.error('No file selected');
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, "", {
      duration: 3000
    });
  }

}
