<div class="p-md-4" >
    <div class="payment-methos">
        <h1>
            {{'PAYMENT METHODS'  | translate}}
        </h1>

        <img src="assets/Frame 12.png">

    </div>

</div>