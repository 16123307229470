import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NavComponent } from '../nav/nav.component';
import { FooterComponent } from '../footer/footer.component';
import { DescriptionComponent } from '../../DetailCourse/description/description.component';
import { ContentComponent } from '../../DetailCourse/content/content.component';
import { JoinComponent } from '../../DetailCourse/join/join.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-path',
  standalone: true,

    imports: [TranslateModule,NavComponent, PathComponent, FooterComponent, DescriptionComponent, ContentComponent, JoinComponent, HttpClientModule],

  templateUrl: './path.component.html',
  styleUrl: './path.component.scss'
})
export class PathComponent {





  constructor(private translate: TranslateService,private http: HttpClient, private route: ActivatedRoute, private router: Router) {

  }
  currentPath=window.location.pathname.split("/").filter((el,i)=>{return (i+1)< window.location.pathname.split("/").length }).join("/").replaceAll("cources","Modules").replaceAll("/"," > ")
 name: string = ""
 description: string = ""
 category: string = ""
 videoUrl: string = ""
 picture: string = ""
 briefing:string=""
 id:string=""
 price:string=""

 passingScore:number=0

 instructor:string=""
 syllabus:string=""
 precondition:string=""
 teachingMethod:string=""
 testimonials:string=""

  faqList:any[]=[]

  formationIdentifier = ""
  somedate = ""
  apprentissage = ""
  mode = ""

 ngOnInit(): void {

   this.translate.addLangs(['en', 'fr']);

   try {
     if(localStorage.getItem("lang")){
       this.translate.setDefaultLang(localStorage.getItem("lang")|| "");

     }

   } catch (error) {
     this.translate.setDefaultLang('fr');

   }
   const apiUrl = ` https://soacwaaslearning.studiolab.fr/elearning/course/getCource/${this.route.snapshot.paramMap.get('id')}`
   this.http.get(apiUrl).subscribe((result: any) => {
     this.name = result.name;
     this.passingScore=result.passingScore
     this.category = result.category;
     this.videoUrl = result.videoUrl;
     this.briefing = result.briefing;
     this.picture = result.picture;
     this.price = result.price;
     this.id = result.id;

     this.description = result.description;

     this.instructor = result.instructor;
     this.syllabus = result.syllabus;
     this.precondition = result.precondition;
     this.teachingMethod = result.teachingMethod;
     this.testimonials = result.testimonials;

     this.faqList=result.faqList
     this.formationIdentifier=result.formationIdentifier
     this.somedate=result.somedate
     this.apprentissage=result.apprentissage
     this.mode=result.mode

   })
 }

 changeLanguage=(lang:string)=>{
   this.translate.setDefaultLang(lang)
 }

}
