import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-question-type1',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './question-type1.component.html',
  styleUrl: './question-type1.component.scss'
})
export class QuestionType1Component {

  @Input() qt: any;
  @Input() questionNumber: number=0;


}
