import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password-success',
  standalone: true,
  imports: [  TranslateModule,
    MatButtonModule, MatMenuModule, MatIconModule],
  templateUrl: './forgot-password-success.component.html',
  styleUrl: './forgot-password-success.component.scss'
})
export class ForgotPasswordSuccessComponent {
  constructor(private router: Router,private translate: TranslateService) {
   
   }
   ngOnInit(): void {



    this.translate.addLangs(['en', 'fr']);

    try {
      if (localStorage.getItem("lang")) {
        this.translate.setDefaultLang(localStorage.getItem("lang") || "");
      }else{
        this.translate.setDefaultLang('fr');
      }

    } catch (error) {


    }}

   changeLanguage = (lang: string) => {
    this.translate.setDefaultLang(lang)
    localStorage.setItem("lang", lang)
  }

  getFlag(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "assets/en.png"
      } else {
        return "assets/fr.png"
      }
    } catch (e) {
      return "assets/fr.png"

    }
  }
  getDiffFlag(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "assets/fr.png"
      } else {
        return "assets/en.png"
      }
    } catch (e) {
      return "assets/en.png"

    }
  }
  getDiffLang(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "Francais"
      } else {
        return "English"
      }
    } catch (e) {
      return "English"

    }
  }
  getDiffLangAp(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "fr"
      } else {
        return "en"
      }
    } catch (e) {
      return "en"

    }
  }
  navigateToAbout() {
    this.router.navigate(['/new-password']);
  }

}
