
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';


import {catchError} from "rxjs/operators";

import { StorageServiceService } from '../storage.service.service';
import { UserDtoModel } from '../models/UserDtoModel';
import { UpdateUserDtoModel } from '../models/UpdateUserDtoModel';

// Assurez-vous d'importer vos modèles

const API_URL = 'https://soacwaaslearning.studiolab.fr/elearning/api/';
const API_URL1 = 'https://soacwaaslearning.studiolab.fr/elearning/api/auth';
const TEST_URL = API_URL + 'test/';
const USERS_URL = API_URL + 'users/';



@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = 'https://soacwaaslearning.studiolab.fr/elearning/api/users';
  private apiUrl = 'https://soacwaaslearning.studiolab.fr/elearning/api/users/';
  [x: string]: any;
  loadUserDetails(): any {
    throw new Error('Method not implemented.');
  }
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private storageService: StorageServiceService,

  ) {}

  // Fonction pour obtenir l'en-tête d'autorisation
  private getHeaders(): HttpHeaders {
    const token = this.authService.getToken() || this.getTokenFromStorage();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);

    }

    return headers;
  }

  getTotalUsers(): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}count`);
  }

  // Récupère le jeton depuis le service de stockage
  private getTokenFromStorage(): string | null {
    const user = this.storageService.getUser();
    return user?.token || null;
  }

  // Requête pour obtenir le contenu public
  getPublicContent(): Observable<string> {
    return this.http.get(TEST_URL + 'all', { responseType: 'text' });
  }

  // Requête pour obtenir le contenu de l'utilisateur
  getUserBoard(): Observable<string> {
    return this.http.get(TEST_URL + 'user', { headers: this.getHeaders(), responseType: 'text' });
  }

  // Requête pour obtenir le tableau du développeur
  getDeveloperBoard(): Observable<string> {
    return this.http.get(TEST_URL + 'developer', { headers: this.getHeaders(), responseType: 'text' });
  }

  // Requête pour obtenir le tableau de l'administrateur
  getAdminBoard(): Observable<string> {
    return this.http.get(TEST_URL + 'admin', { headers: this.getHeaders(), responseType: 'text' });
  }

  // Requête pour obtenir les détails d'un utilisateur spécifique
  getUserDetails(username: string): Observable<UserDtoModel> {
    return this.http.get<UserDtoModel>(USERS_URL + username, { headers: this.getHeaders() });
  }

  // Requête pour mettre à jour les détails d'un utilisateur
  updateUserDetails(username: string, updateUserDTO: UpdateUserDtoModel): Observable<any> {
    return this.http.put(USERS_URL + username, updateUserDTO, { headers: this.getHeaders() });
  }

  updateImage(file: File, id: number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.put(`${this.baseUrl}/updateimage/${id}`, formData);
  }


  // Ajoutez la méthode getAllUsers à UserService
  getAllUsers(): Observable<UserDtoModel[]> {
    // Effectuez une requête GET à l'URL appropriée (USERS_URL)
    return this.http.get<UserDtoModel[]>(USERS_URL, { headers: this.getHeaders() });
  }
  resetPassword(token: string, newPassword: string): Observable<any> {
    const body = { token: token, password: newPassword };
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Custom-Header', 'value'); // Ajoutez vos en-têtes personnalisés ici

    return this.http.post<any>(API_URL1 +'/reset-password', body, { headers: headers });
  }

  createCompany(user: UserDtoModel): Observable<UserDtoModel> {
    return this.http.post<UserDtoModel>(this.apiUrl, user);
  }



  updateCource(user: UpdateUserDtoModel): Observable<UpdateUserDtoModel> {
    return this.http.put<UpdateUserDtoModel>(`${this.apiUrl}`, user);
  }


}
