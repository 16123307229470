import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders, HttpEventType } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { YesOrNoComponent } from '../../../compoments/commun/yes-or-no/yes-or-no.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

export interface Question {
  id: number;
  question: string;
  notes: string;
  points: number;
  questionType: string;
  answer: string;

  options: string[];
  answers: string[];
}

@Component({
  selector: 'app-edit-exam',
  standalone: true,
  imports: [MatProgressSpinnerModule,TranslateModule,YesOrNoComponent, MatButtonModule, MatChipsModule, MatIconModule, HttpClientModule, CommonModule, FormsModule, MatSelectModule, MatInputModule, MatFormFieldModule],
  templateUrl: './edit-exam.component.html',
  styleUrl: './edit-exam.component.scss'
})
export class EditExamComponent implements OnInit {
  constructor(private translate: TranslateService,private _snackBar: MatSnackBar,public dialog: MatDialog, private http: HttpClient, private route: ActivatedRoute, private router: Router) {

  }
  name = ""
  questions: Question[] = []

  showAddform = false
  addState = true

  newQuestion = ""
  newNotes = ""
  newPoints = 0
  newQuestionType = "writin"
  newAnswer = ""
  newOptions: string[] = []
  newOptionsInput = ""
  newAnswers: string[] = []
  newAnswersInput = ""

  updatedId:number=-1

  questionTypes = ["writin", "single answer", "multiple answers"]



  ngOnInit(): void {

    this.translate.addLangs(['en', 'fr']);

    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");
      }else{
        this.translate.setDefaultLang('fr');
      }
    } catch (error) {
      this.translate.setDefaultLang('fr');
    }
    const apiUrl = `https://soacwaaslearning.studiolab.fr/elearning/course/getCource/${this.route.snapshot.paramMap.get('id')}`
    this.http.get(apiUrl).subscribe((result: any) => {
      this.name = result.name;
    })

    this.getQuestions()
  }

  getQuestions(){
    const apiUrl1 = `https://soacwaaslearning.studiolab.fr/elearning/question/getQuestionsByCourse/${this.route.snapshot.paramMap.get('id')}`

    this.http.get(apiUrl1).subscribe((result: any) => {
      this.questions = result;


    })
  }
  addnewOptions() {
    this.newOptions.push(this.newOptionsInput)
    this.newOptionsInput = ""
  }

  changeLanguage=(lang:string)=>{
    this.translate.setDefaultLang(lang)
  }


  addnewAnswers() {
    this.newAnswers.push(this.newAnswersInput)
    this.newAnswersInput = ""
  }

  addNewQuestion() {
    this.showAddform = true
    this.newQuestion = ""
    this.newNotes = ""
    this.newPoints = 0
    this.newQuestionType = "writin"
    this.newAnswer = ""
    this.newOptions = []
    this.newOptionsInput = ""
    this.newAnswers = []
    this.newAnswersInput = ""

  }
  openDialog(idQuestion: number): void {
    const dialogRef = this.dialog.open(YesOrNoComponent);

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.deleteQuestion(idQuestion)
      } else {
        // alert("no")
      }
    });
  }

  saveQuestion() {
    const apiUrl = `https://soacwaaslearning.studiolab.fr/elearning/question/update`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  
    let q = {
      "id": this.updatedId,
      "question": this.newQuestion,
      "notes": this.newNotes,
      "points": this.newPoints,
      "questionType": this.newQuestionType,
      "answer": this.newAnswer,
      "options": this.newOptions,
      "answers": this.newAnswers
    };
  
    this.http.put(apiUrl, q, { headers }).subscribe(result => {
      const successMessage = this.translate.instant('question_updated_successfully'); // Utilisation d'une clé de traduction
      
      this.openSnackBar(successMessage);
      
      setTimeout(() => {
        this.getQuestions();
        this.showAddform = false;
      }, 500);
    });
  }
  

  createNewQuestion() {
    const apiUrl = `https://soacwaaslearning.studiolab.fr/elearning/question/create/${this.route.snapshot.paramMap.get('id')}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  
    let q = {
      "question": this.newQuestion,
      "notes": this.newNotes,
      "points": this.newPoints,
      "questionType": this.newQuestionType,
      "answer": this.newAnswer,
      "options": this.newOptions,
      "answers": this.newAnswers
    };
  
    this.http.post(apiUrl, q, { headers }).subscribe(result => {
      const successMessage = this.translate.instant('question_created_successfully'); // Utilisation d'une clé de traduction
  
      this.openSnackBar(successMessage);
      
      setTimeout(() => {
        this.getQuestions();
        this.showAddform = false;
      }, 500);
    });
  }
  

  select2edit(idQuestion: number) {
    this.showAddform = true
    this.addState = false
    this.updatedId = this.questions[idQuestion].id
    this.newQuestion = this.questions[idQuestion].question
    this.newNotes = this.questions[idQuestion].notes
    this.newPoints = this.questions[idQuestion].points
    this.newQuestionType = this.questions[idQuestion].questionType
    this.newAnswer = this.questions[idQuestion].answer
    this.newOptions = this.questions[idQuestion].options
    this.newAnswers = this.questions[idQuestion].answers

  }

  deleteQuestion(idQuestion: number) {
    const apiUrl = `https://soacwaaslearning.studiolab.fr/elearning/question/cource/${this.route.snapshot.paramMap.get('id')}/question/${idQuestion}`;
    
    this.http.delete(apiUrl).subscribe(result => {
      const successMessage = this.translate.instant('question_deleted_successfully1'); // Utilisation d'une clé de traduction
      
      this.openSnackBar(successMessage);
      
      setTimeout(() => {
        this.getQuestions();
      }, 500);
    });
  }
  

    openSnackBar(message: string) {
    this._snackBar.open(message, "", {
      duration: 3000
    });
  }
}
