<div class="p-5" id=" id_cours">
    <div class="row m-5">
        <div class="col-md-4">
            <h2>       {{ 'Our_recommended_semsoc_themes_for_you' | translate }}</h2>
        </div>
       <div class="col-md-8 d-flex flex-column justify-content-between">
            <div class="row d-flex justify-content-end">
                <select style="border: 1px solid black; border-radius: 5px;" (change)="changeCatergory($event)" id="exampleSelect">
                    <option value="">{{ 'ALL_CATEGORIES' | translate }}</option>
                    <option value="TRAINING FOR TESTING AND ANALYSIS LABORATORIES">{{ 'TRAINING FOR TESTING AND ANALYSIS LABORATORIES' | translate }}</option>
                    <option value="TRAINING FOR LABORATORIES">{{ 'TRAINING FOR LABORATORIES' | translate }}</option>
                    <option value="TRAINING FOR MEDICAL BIOLOGY LABORATORIES">{{ 'TRAINING FOR MEDICAL BIOLOGY LABORATORIES' | translate }}</option>
                    <option value="TRAINING FOR CERTIFICATION BODIES">{{ 'TRAINING FOR CERTIFICATION BODIES' | translate }}</option>
                    <option value="TRAINING FOR PROFICIENCY TESTING BODIES">{{ 'TRAINING FOR PROFICIENCY TESTING BODIES' | translate }}</option>
                    <option value="TRAINING FOR VERIFICATION AND VALIDATION BODIES">{{ 'TRAINING FOR VERIFICATION AND VALIDATION BODIES' | translate }}</option>
                </select>
                <button (click)="search()" class="btn">{{ 'VIEW_ALL' | translate }}</button>
            </div>
            <!-- <div class="row d-flex justify-content-end">
                <a href="#id-header">{{ 'HOME' | translate }}</a>
                <a href="#id-obout">{{ 'ABOUT US' | translate }}</a>
                <a href="/cources">{{ 'COURSES' | translate }}</a>
                <a href="https://www.soacwaas.org/actualites.html">
                    {{ 'BLOG' | translate }}
                    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.33333 3.56972H4.99999C4.55797 3.56972 4.13404 3.68085 3.82148 3.87867C3.50892 4.07649 3.33333 4.34479 3.33333 4.62455V9.89868C3.33333 10.1784 3.50892 10.4467 3.82148 10.6446C4.13404 10.8424 4.55797 10.9535 4.99999 10.9535H13.3333C13.7754 10.9535 14.1993 10.8424 14.5118 10.6446C14.8244 10.4467 15 10.1784 15 9.89868V7.78903M11.6667 2.51489H16.6667M16.6667 2.51489V5.67937M16.6667 2.51489L8.33333 7.78903"
                            stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </a>
            </div> -->
        </div>
    </div>
    <hr>
    <app-items [cources]="cources"></app-items>
</div>