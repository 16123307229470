import { HttpClient, HttpClientModule, HttpHeaders ,HttpEventType} from '@angular/common/http';
import { Component , OnInit} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {MatChipsModule} from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { AngularEditorModule } from '@kolkov/angular-editor';

import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-editor',
  standalone: true,
  imports: [DatePipe,MatProgressSpinnerModule,MatButtonModule,  TranslateModule,MatProgressBarModule,HttpClientModule, AngularEditorModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatTabsModule, MatIconModule,MatChipsModule],
 templateUrl: './editor.component.html',
  styleUrl: './editor.component.scss'
})
export class EditorComponent implements OnInit{
  ngOnInit(): void {
    this.translate.addLangs(['en', 'fr']);

    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");
      }else{
        this.translate.setDefaultLang('fr');
      }
    } catch (error) {
      this.translate.setDefaultLang('fr');
    }
  }
  isloading=false

  selectedFile: File | null = null;
  progress: number = 0;
  videoUrl: string = '';

  name = ""
  picture=""
  description = ""
  instructor = ""
  syllabus = ""
  precondition = ""
  teachingMethod = ""
  testimonials = ""
  fourmateur1 = ""
  fourmateur2 = ""
  formationIdentifier = ""
  somedate = ""
  somedate2 = ""
  apprentissage = ""
  mode = ""


  briefing=""
  category = ""

  newChapter = ""

  chapters: string[]  =[]

  price = 0
  passingScore=0
  startDate: Date | null = null;
  endDate: Date | null = null;
  formattedDateRange: string = '';


  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(private translate: TranslateService,private _snackBar: MatSnackBar,private http: HttpClient,private router: Router) {  translate.addLangs(['en', 'fr']);

  }
  openSnackBar(message: string) {
    this._snackBar.open(message,"",{
      duration: 3000
    });
  }



  removeChapter(cp:string){
    this.chapters.splice(this.chapters.indexOf(cp),1)
  }
  createCourse() {
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/course/create";
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  
    let data1 = {
      videoUrl: this.videoUrl,
      name: this.name,
      description: this.description,
      briefing: this.briefing,
      instructor: this.instructor,
      syllabus: this.syllabus,
      precondition: this.precondition,
      teachingMethod: this.teachingMethod,
      testimonials: this.testimonials,
      category: this.category,
      price: this.price,
      chapters: this.chapters,
      picture: this.picture,
      passingScore: this.passingScore,
      fourmateur1: this.fourmateur1,
      fourmateur2: this.fourmateur2,
      formationIdentifier: this.formationIdentifier,
      somedate: this.somedate,
      somedate2: this.somedate2,
      apprentissage: this.apprentissage,
      mode: this.mode
    };
  
    this.http.post(apiUrl, data1, { headers }).subscribe(result => {
      const message = this.translate.instant('COURSE_CREATED_SUCCESSFULLY');
      this.openSnackBar(message);
      setTimeout(() => {
        this.router.navigate(['dashboard-admin/cources']);
      }, 2000);
    });
  }
  

  changeLanguage=(lang:string)=>{
    this.translate.setDefaultLang(lang)
  }

  addChapters(){
    this.chapters.push(this.newChapter)
    this.newChapter=""
  }

  uploadImage(e:any){
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    this.http.post('https://soacwaaslearning.studiolab.fr/elearning/api/images/upload', formData, {
        reportProgress: true,
        observe: 'events'
      })
        .subscribe((event: any) => {
            this.picture = `https://soacwaaslearning.studiolab.fr/elearning/api/images/view/${event.body.message}`;
        });
  }

  onFileSelected(event: Event): void { // Explicitly type the event parameter
    const input = event.target as HTMLInputElement;
    if (input && input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
      const videoURL = URL.createObjectURL(this.selectedFile);

      // Create a video element
      const videoElement = document.createElement('video');
      videoElement.src = videoURL;

      // Listen for the loadedmetadata event to get the video duration
      videoElement.addEventListener('loadedmetadata', () => {


        // Clean up the object URL
        URL.revokeObjectURL(videoURL);
      });

    } else {
      this.selectedFile = null;
    }
  }

  onUpload(): void {
    this.videoUrl=""
    if (this.selectedFile) { // Type guard to check if selectedFile is not null
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);
      this.isloading=true
      this.http.post('https://soacwaaslearning.studiolab.fr/elearning/api/videos/upload', formData, {
        reportProgress: true,
        observe: 'events'
      })
        .subscribe((event: any) => {

          if (event.type === HttpEventType.UploadProgress && event.total) {
            this.progress = Math.round((event.loaded / event.total) * 100);
          } else if (event.type === HttpEventType.Response) {
            this.isloading=false

            this.videoUrl = `https://soacwaaslearning.studiolab.fr/elearning/api/videos/stream/${event["body"]["name"]}`;
          }
        }, error => {
          console.error('Error in POST request:', error);
        });
    } else {
      console.error('No file selected');
    }
  }

}
