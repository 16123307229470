<div class="container">
    <h1 class="display-4 mb-5 mt-5 text-center">
        Pourquoi une formation SEMSOAC

    
    </h1>
<!-- benefits.component.html -->

<button   class="add"   mat-mini-fab color="primary" style="background-color: #FFC825;" (click)="goToAddPage()" >
    <mat-icon>add</mat-icon>
</button>


    <div class="row">
        <div *ngFor="let benefit of benefits" class="col-md-4">
            <div class="card mb-4 shadow">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <h3 class="card-title">{{ benefit.id }}</h3>
                        <div class="action-buttons">
                           
                            <button mat-mini-fab color="primary" style="background-color: #FFC825;"  (click)="deleteBenefit(benefit.id)">
                                <mat-icon>delete</mat-icon>
                            </button>
                          
                        </div>
                    </div>
                    <h2 class="card-subtitle mb-3">{{ benefit.titre }}</h2>
                    <p class="card-text">{{ benefit.description }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
