import { Component, Output, EventEmitter, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { StorageServiceService } from '../../../storage.service.service';
import { AuthService } from '../../../Services/auth.service';
import { ProfileComponent } from '../../../profile/profile.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';




@Component({
  selector: 'app-navbar-leasson',
  standalone: true,
  imports: [TranslateModule,MatButtonModule, MatSidenavModule, MatIconModule, MatMenuModule, CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule],
  templateUrl: './navbar-leasson.component.html',
  styleUrl: './navbar-leasson.component.scss'
})



export class NavbarLeassonComponent {


  public roles: string[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showDevBoard = false;
  username?: string;

  eventBusSub?: Subscription;
  session!: any;
  profileImageUrl: string | undefined;
  @Output() sidenav: EventEmitter<any> = new EventEmitter();
  isIconToggled: boolean = false;
  isLoged: boolean = true
  constructor(
    private translate: TranslateService,
    private storageService: StorageServiceService,
    private authService: AuthService,
    private router: Router) {


    }

    getFlag(): string {
      try {
        if (localStorage.getItem("lang") == "en") {
          return "assets/en.png"
        } else {
          return "assets/fr.png"
        }
      } catch (e) {
        return "assets/fr.png"

      }
    }
    getDiffFlag(): string {
      try {
        if (localStorage.getItem("lang") == "en") {
          return "assets/fr.png"
        } else {
          return "assets/en.png"
        }
      } catch (e) {
        return "assets/en.png"

      }
    }
    getDiffLang(): string {
      try {
        if (localStorage.getItem("lang") == "en") {
          return "Francais"
        } else {
          return "English"
        }
      } catch (e) {
        return "English"

      }
    }
    getDiffLangAp(): string {
      try {
        if (localStorage.getItem("lang") == "en") {
          return "fr"
        } else {
          return "en"
        }
      } catch (e) {
        return "en"

      }
    }
  ngOnInit(): void {
    this.translate.addLangs(['en', 'fr']);

    try {
      if (localStorage.getItem("lang")) {
        this.translate.setDefaultLang(localStorage.getItem("lang") || "");
      }else{
        this.translate.setDefaultLang('fr');
      }

    } catch (error) {


    }
    this.isLoggedIn = this.storageService.isLoggedIn();

    if (this.isLoggedIn) {
      const user = this.storageService.getUser();
      this.roles = user.roles;

      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showDevBoard = this.roles.includes('ROLE_DEVELOPER');

      this.username = user.username;

      // Obtenir l'image de profil de l'utilisateur connecté
      if (this.username) {
        this.authService.getProfileImage(this.username).subscribe({
          next: (response) => {
            // Créez une URL d'objet pour le Blob et stockez-la dans profileImageUrl

            this.profileImageUrl = URL.createObjectURL(response);
          },
          error: (err) => {
            console.error('Erreur lors de la récupération de l\'image de profil:', err);
          }
        });
      }
    }


  }


  profile():void{
    this.router.navigateByUrl('/profile');
  }


  logout(): void {
    this.authService.logout().subscribe({
      next: res => {
        this.storageService.clean();
        localStorage.clear();
        this.router.navigateByUrl('/login');
      },
      error: err => {
        console.log(err);
      }
    });
  }


  toggle() {
    this.isIconToggled = !this.isIconToggled;

    this.sidenav.emit();
  }

  navigateToAbout() {
    this.router.navigate(['/dashboard-student']);
  }
  navigateToCources() {
    this.router.navigate(['/cources']);
  }

  navigateToProfile(){
    this.router.navigate(['/profile']);
  }

  changeLanguage = (lang: string) => {
    this.translate.setDefaultLang(lang)
    localStorage.setItem("lang", lang)
  }


}
