import { Component, OnInit } from '@angular/core';
import { NavComponent } from '../../compoments/commun/nav/nav.component';
import { PathComponent } from '../../compoments/commun/path/path.component';
import { SearchComponent } from '../../compoments/cources/search/search.component';
import { ItemsComponent } from '../../compoments/cources/items/items.component';
import { PaginationComponent } from '../../compoments/commun/pagination/pagination.component';
import { FooterComponent } from '../../compoments/commun/footer/footer.component';
import { HttpClient, HttpClientModule, HttpHeaders, HttpParams } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { Page } from '../../models/page.model';

@Component({
  selector: 'app-cources',
  standalone: true,
  imports: [CommonModule,NavComponent, PathComponent, SearchComponent, ItemsComponent, PaginationComponent, FooterComponent, HttpClientModule],
  templateUrl: './cources.component.html',
  styleUrl: './cources.component.scss'
})
export class CourcesComponent implements OnInit {
  constructor(private http: HttpClient) { }
  name = ""
  category = ""
  pageNumber = 1
  pageSize = 5
  pages = ["1"]
  currentPage!: Page<any>
  ngOnInit(): void {



    this.search()
  }

  goToPage=(t:number)=>{
    this.pageNumber = t
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/course/searchCources"

    let params = new HttpParams()
      .set('name', this.name)
      .set('category', this.category)
      .set('pageNumber', (t - 1))
      .set('pageSize', this.pageSize);
    this.http.get<any>(`${apiUrl}`, { params, headers }).subscribe(data => {
      this.currentPage = data
      this.getPaginationButtons(data.totalPages,t)

    })
  }

  searchbyWord=(s:string)=>{
    this.name=s
    this.pageNumber=1
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/course/searchCources"

    let params = new HttpParams()
      .set('name',s)
      .set('category', this.category)
      .set('pageNumber', ( 0).toString())
      .set('pageSize', this.pageSize);
    this.http.get<any>(`${apiUrl}`, { params, headers }).subscribe(data => {
      this.currentPage = data
      this.getPaginationButtons(data.totalPages,this.pageNumber)

    })
  }

  searchbyCategory=(c:string)=>{
    this.category=c
    this.pageNumber=1

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/course/searchCources"

    let params = new HttpParams()
      .set('name',this.name)
      .set('category', c)
      .set('pageNumber', (0).toString())
      .set('pageSize', this.pageSize);
    this.http.get<any>(`${apiUrl}`, { params, headers }).subscribe(data => {
      this.currentPage = data
      this.getPaginationButtons(data.totalPages,this.pageNumber)

    })
  }


  search() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const apiUrl = "https://soacwaaslearning.studiolab.fr/elearning/course/searchCources"

    let params = new HttpParams()
      .set('name', this.name)
      .set('category', this.category)
      .set('pageNumber', (this.pageNumber - 1).toString())
      .set('pageSize', this.pageSize.toString());
    this.http.get<any>(`${apiUrl}`, { params, headers }).subscribe(data => {
      this.currentPage = data
      this.getPaginationButtons(data.totalPages,this.pageNumber)

    })

  }




  getPaginationButtons(totalPages: number, currentPage: number) {
    const paginationButtons = [];
    const delta = 2; // Number of buttons to show on each side of the current page
    const left = currentPage - delta;
    const right = currentPage + delta;

    // Ensure the currentPage is within the valid range
    currentPage = Math.max(1, Math.min(currentPage, totalPages));

    // Generate the pagination buttons
    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= left && i <= right)) {
        paginationButtons.push(i.toString());
      } else if (i === left - 1 || i === right + 1) {
        paginationButtons.push("...");
      }
    }

    // Remove duplicate ellipses
    this.pages = paginationButtons.filter((item, pos, arr) =>
      item !== "..." || (pos > 0 && arr[pos - 1] !== "...")
    );
  }
}
