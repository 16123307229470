<div class="search-container">
    <input type="text" (keydown.enter)="onEnter()" [(ngModel)]="search" class="form-control custom-input" [placeholder]="'SEARCH_PLACEHOLDER' | translate">
    <div>
        <span (click)="selectCat('',$event)" class="search-category btn btn-outline-primary active button1">{{ 'CATEGORY_ALL' | translate }}</span>
        <span (click)="selectCat('TRAINING FOR PROFICIENCY TESTING BODIES',$event)" class="button2 search-category btn btn-outline-primary">{{ 'TRAINING FOR PROFICIENCY TESTING BODIES' | translate }}</span>
        <span (click)="selectCat('TRAINING FOR VERIFICATION AND VALIDATION BODIES',$event)" class="button3 search-category btn btn-outline-primary">{{ 'TRAINING FOR VERIFICATION AND VALIDATION BODIES' | translate }}</span>
        <span (click)="selectCat('TRAINING FOR TESTING AND ANALYSIS LABORATORIES',$event)" class="button4 search-category btn btn-outline-primary">{{ 'TRAINING FOR TESTING AND ANALYSIS LABORATORIES' | translate }}</span>
        <span (click)="selectCat('TRAINING FOR LABORATORIES',$event)" class="button5 search-category btn btn-outline-primary">{{ 'TRAINING FOR LABORATORIES' | translate }}</span>
        <span (click)="selectCat('TRAINING FOR MEDICAL BIOLOGY LABORATORIES',$event)" class="button6 search-category btn btn-outline-primary">{{ 'TRAINING FOR MEDICAL BIOLOGY LABORATORIES' | translate }}</span>
        <span (click)="selectCat('TRAINING FOR CERTIFICATION BODIES',$event)" class="button7 search-category btn btn-outline-primary">{{ 'TRAINING FOR CERTIFICATION BODIES' | translate }}</span>

    </div>
</div>
