import { Component } from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import { NavComponent } from '../../compoments/commun/nav/nav.component';
import { HeaderComponent } from '../../compoments/home/header/header.component';
import { CountersComponent } from '../../compoments/home/counters/counters.component';
import { BenefitsComponent } from '../../compoments/home/benefits/benefits.component';
import { AboutUsComponent } from '../../compoments/home/about-us/about-us.component';
import { RecommendationsComponent } from '../../compoments/home/recommendations/recommendations.component';
import { FooterComponent } from '../../compoments/commun/footer/footer.component';
import { GeneralFaqComponent } from '../../compoments/home/general-faq/general-faq.component';
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [GeneralFaqComponent,MatIconModule,MatDividerModule,MatButtonModule,NavComponent,HeaderComponent,CountersComponent,BenefitsComponent,AboutUsComponent,RecommendationsComponent,FooterComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

}
