<div class="container py-4">
    <div class="payment-form p-4">
        <div id="paypal-button-container"></div>

        <!-- <h1 class="text-center">{{ 'payment_form_title' | translate }}</h1>
        <h2 class="text-success">{{ 'payment_information_title' | translate }}</h2>
        <hr>
        <div class="row gy-3">
            <div class="col-md-7">
                <label for="paymentMethod" class="form-label">{{ 'select_payment_method_label' | translate }}</label>
                <select class="form-select" id="paymentMethod">
                    <option hidden value="">{{ 'select_placeholder' | translate }}</option>
                    <option value="option1">{{ 'option1' | translate }}</option>
                    <option value="option2">{{ 'option2' | translate }}</option>
                    <option value="option3">{{ 'option3' | translate }}</option>
                    <option value="option4">{{ 'option4' | translate }}</option>
                </select>
            </div>
            <div class="col-md-5">
                <label for="expirationDate" class="form-label">{{ 'expiration_date_label' | translate }}</label>
                <input type="date" class="form-control" id="expirationDate">
            </div>
            <div class="col-md-7">
                <label for="cardNumber" class="form-label">{{ 'card_number_label' | translate }}</label>
                <input type="text" class="form-control" id="cardNumber">
            </div>
            <div class="col-md-5">
                <label for="securityCode" class="form-label">{{ 'security_code_label' | translate }}</label>
                <input type="text" class="form-control" id="securityCode">
            </div>
        </div>
        <h2 class="mt-4">{{ 'billing_information_title' | translate }}</h2>
        <hr>
        <div class="row gy-3">
            <div class="col-md-7">
                <label for="fullName" class="form-label">{{ 'full_name_label' | translate }}</label>
                <input type="text" class="form-control" id="fullName">
            </div>
            <div class="col-md-5">
                <label for="country" class="form-label">{{ 'country_label' | translate }}</label>
                <select class="form-select" id="country">
                    <option hidden value="">{{ 'select_placeholder' | translate }}</option>
                    <option value="option1">{{ 'option1' | translate }}</option>
                    <option value="option2">{{ 'option2' | translate }}</option>
                    <option value="option3">{{ 'option3' | translate }}</option>
                    <option value="option4">{{ 'option4' | translate }}</option>
                </select>
            </div>
            <div class="col-md-7">
                <label for="billingAddress" class="form-label">{{ 'billing_address_label' | translate }}</label>
                <input type="text" class="form-control" id="billingAddress">
            </div>
            <div class="col-md-5">
                <label for="state" class="form-label">{{ 'state_label' | translate }}</label>
                <select class="form-select" id="state">
                    <option hidden value="">{{ 'select_placeholder' | translate }}</option>
                    <option value="option1">{{ 'option1' | translate }}</option>
                    <option value="option2">{{ 'option2' | translate }}</option>
                    <option value="option3">{{ 'option3' | translate }}</option>
                    <option value="option4">{{ 'option4' | translate }}</option>
                </select>
            </div>
            <div class="col-md-7">
                <label for="phoneNumber" class="form-label">{{ 'phone_number_label' | translate }}</label>
                <input type="text" class="form-control" id="phoneNumber">
            </div>
            <div class="col-md-5">
                <label for="postalCode" class="form-label">{{ 'postal_code_label' | translate }}</label>
                <input type="text" class="form-control" id="postalCode">
            </div>
            <div class="col-md-12 d-flex justify-content-center align-items-center mt-3">
                <input class="form-check-input me-2" type="checkbox" id="rememberMe">
                <label class="form-check-label" for="rememberMe">{{ 'remember_me_label' | translate }}</label>
            </div>
            <div class="col-md-12 d-flex justify-content-center mt-3">
                <button (click)="navigateToAbout()" class="btn btn-primary">{{ 'continue_button_label' | translate }}</button>
            </div>
        </div> -->
    </div>
</div>
