<link href="https://cdn.jsdelivr.net/npm/bootstrap-icons/font/bootstrap-icons.css" rel="stylesheet">

<div class="side-bar-leason">
    <div class="logo">
        <img (click)="goToHome()"  class="officialLogo" width="200" height="80" src="assets/SEMSOAC E LEARNING.png" >
    </div>

    <div [ngClass]="{ 'active': getActiveClass('/dashboard-student/cources') }" (click)="navigateToAbout1()" class="top-sidebar-component">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.3001 1.22217L7.7001 4.88883M7.7001 20.7777L1.7084 17.4484C1.52571 17.347 1.37204 17.191 1.26461 16.998C1.15717 16.805 1.10021 16.5826 1.1001 16.3557V3.19972C1.1002 2.99147 1.14819 2.78669 1.23952 2.60483C1.33084 2.42297 1.46248 2.27006 1.62193 2.1606C1.78138 2.05114 1.96336 1.98876 2.1506 1.97939C2.33784 1.97002 2.52413 2.01397 2.6918 2.10706L7.7001 4.88883V20.7777ZM7.7001 20.7777L14.3001 17.1111L7.7001 20.7777ZM7.7001 20.7777V4.88883V20.7777ZM14.3001 17.1111L19.3084 19.8928C19.4761 19.9859 19.6623 20.0299 19.8496 20.0205C20.0368 20.0111 20.2188 19.9488 20.3783 19.8393C20.5377 19.7298 20.6694 19.5769 20.7607 19.3951C20.852 19.2132 20.9 19.0084 20.9001 18.8002V5.64417C20.9 5.41725 20.843 5.19484 20.7356 5.00186C20.6282 4.80887 20.4745 4.65293 20.2918 4.5515L14.3001 1.22217V17.1111ZM14.3001 17.1111V1.22217V17.1111Z"
                stroke="#9197B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        <span> {{ 'myCourses' | translate }}</span>
    </div>
    <div [ngClass]="{ 'active': getActiveClass('/dashboard-student') }" (click)="navigateToAbout2()" class="top-sidebar-component">

        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_930)">
                <path
                    d="M15.8888 6.11106V15.8888M10.9999 9.77772V15.8888M6.11106 13.4444V15.8888M3.66661 20.7777H18.3333C18.9816 20.7777 19.6033 20.5202 20.0618 20.0618C20.5202 19.6033 20.7777 18.9816 20.7777 18.3333V3.66661C20.7777 3.01831 20.5202 2.39655 20.0618 1.93813C19.6033 1.47971 18.9816 1.22217 18.3333 1.22217H3.66661C3.01831 1.22217 2.39655 1.47971 1.93813 1.93813C1.47971 2.39655 1.22217 3.01831 1.22217 3.66661V18.3333C1.22217 18.9816 1.47971 19.6033 1.93813 20.0618C2.39655 20.5202 3.01831 20.7777 3.66661 20.7777Z"
                    stroke="#282938" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1_930">
                    <rect width="22" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>

        <span> {{ 'dashboard' | translate }}</span>
    </div>

    <!-- Arrangement dropdown -->
    <!-- <div class="dropdown">
        <div class="top-sidebar-component dropdown-toggle" id="arrangementDropdown" data-bs-toggle="dropdown" aria-expanded="false">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_942)">
                <path d="M9.1576 2.5488C9.6262 0.617198 12.374 0.617198 12.8426 2.5488C12.9129 2.83898 13.0507 3.10845 13.2449 3.3353C13.439 3.56214 13.684 3.73994 13.9598 3.85422C14.2357 3.9685 14.5346 4.01603 14.8323 3.99295C15.1299 3.96987 15.418 3.87683 15.6729 3.7214C17.3702 2.6874 19.3139 4.63 18.2799 6.3284C18.1247 6.58322 18.0318 6.87107 18.0088 7.16855C17.9857 7.46603 18.0332 7.76474 18.1474 8.04041C18.2615 8.31609 18.4391 8.56093 18.6657 8.75505C18.8923 8.94918 19.1615 9.08709 19.4514 9.1576C21.383 9.6262 21.383 12.374 19.4514 12.8426C19.1612 12.9129 18.8917 13.0507 18.6649 13.2449C18.4381 13.439 18.2603 13.684 18.146 13.9598C18.0317 14.2357 17.9842 14.5346 18.0072 14.8323C18.0303 15.1299 18.1234 15.418 18.2788 15.6729C19.3128 17.3702 17.3702 19.3139 15.6718 18.2799C15.417 18.1247 15.1291 18.0318 14.8316 18.0088C14.5342 17.9857 14.2355 18.0332 13.9598 18.1474C13.6841 18.2615 13.4393 18.4391 13.2451 18.6657C13.051 18.8923 12.9131 19.1615 12.8426 19.4514C12.374 21.383 9.6262 21.383 9.1576 19.4514C9.08729 19.1612 8.94945 18.8917 8.75532 18.6649C8.56118 18.4381 8.31622 18.2603 8.04038 18.146C7.76454 18.0317 7.46562 17.9842 7.16793 18.0072C6.87025 18.0303 6.58222 18.1234 6.3273 18.2788C4.63 19.3128 2.6863 17.3702 3.7203 15.6718C3.87551 15.417 3.9684 15.1291 3.99143 14.8316C4.01446 14.5342 3.96696 14.2355 3.85282 13.9598C3.73867 13.6841 3.56108 13.4393 3.3345 13.2451C3.10791 13.051 2.83872 12.9131 2.5488 12.8426C0.617198 12.374 0.617198 9.6262 2.5488 9.1576C2.83898 9.08729 3.10845 8.94945 3.3353 8.75532C3.56214 8.56118 3.73994 8.31622 3.85422 8.04038C3.9685 7.76454 4.01603 7.46562 3.99295 7.16793C3.96987 6.87025 3.87683 6.58222 3.7214 6.3273C2.6874 4.63 4.63 2.6863 6.3284 3.7203C7.424 4.3891 8.854 3.7973 9.1576 2.5488Z" stroke="#9197B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.3002 11C14.3002 11.8752 13.9525 12.7145 13.3336 13.3334C12.7148 13.9523 11.8754 14.3 11.0002 14.3C10.125 14.3 9.28561 13.9523 8.66674 13.3334C8.04787 12.7145 7.7002 11.8752 7.7002 11C7.7002 10.1247 8.04787 9.28537 8.66674 8.6665C9.28561 8.04763 10.125 7.69995 11.0002 7.69995C11.8754 7.69995 12.7148 8.04763 13.3336 8.6665C13.9525 9.28537 14.3002 10.1247 14.3002 11V11Z" stroke="#9197B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_1_942">
                <rect width="22" height="22" fill="white"/>
                </clipPath>
                </defs>
                </svg>

            <span>Arrangement</span>
        </div>
        <ul class="dropdown-menu" aria-labelledby="arrangementDropdown">
            <li><a class="dropdown-item" href="#">Name</a></li>
            <li><a class="dropdown-item" href="#">Surname</a></li>
        </ul>
    </div> -->
</div>

<div class="dashboard">
    <!-- Dashboard content -->
</div>

<!-- Include Bootstrap JS at the end of your HTML file -->
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js"></script>