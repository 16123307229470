import { Injectable } from '@angular/core';
const USER_KEY = 'auth-user';
@Injectable({
  providedIn: 'root'
})
export class StorageServiceService {
  clean(): void {
    try {
      window.sessionStorage.clear();
    } catch (error) {

    }

  }

  public saveUser(user: any): void {
    try {
      if (Boolean(localStorage.getItem("saveInfos"))) {
        localStorage.removeItem(USER_KEY)
        localStorage.setItem(USER_KEY, JSON.stringify(user));
      } else {
        window.sessionStorage.removeItem(USER_KEY);
        window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
      }
    } catch (error) {
      try {
        window.sessionStorage.removeItem(USER_KEY);
        window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
      } catch (error) {

      }

    }
  }

  public getUser(): any {
    try {
      if (localStorage.getItem(USER_KEY)) {

        return JSON.parse(localStorage.getItem(USER_KEY) || "");
      } else if (window.sessionStorage.getItem(USER_KEY)) {

        return JSON.parse(window.sessionStorage.getItem(USER_KEY) || "");
      }

    } catch (error) {
    }

    return {};
  }

  public isLoggedIn(): boolean {
    try {
      if (localStorage.getItem(USER_KEY)) {

        return true;
      } else if (window.sessionStorage.getItem(USER_KEY)) {

        return true;
      } else {
        return false;
      }

    } catch (error) {
    }


    return false;
  }
}
