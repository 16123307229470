import { Component, OnInit } from '@angular/core';
import { NavComponent } from '../../compoments/commun/nav/nav.component';
import { PathComponent } from '../../compoments/commun/path/path.component';
import { FooterComponent } from '../../compoments/commun/footer/footer.component';
import { ExamDescriptionComponent } from '../../compoments/exam-questions/exam-description/exam-description.component';
import { QuestionType1Component } from '../../compoments/exam-questions/question-type1/question-type1.component';
import { QuestionType2Component } from '../../compoments/exam-questions/question-type2/question-type2.component';
import { QuestionType3Component } from '../../compoments/exam-questions/question-type3/question-type3.component';
import { QuestionType4Component } from '../../compoments/exam-questions/question-type4/question-type4.component';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpClientModule, HttpHeaders, HttpEventType } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { StorageServiceService } from '../../storage.service.service';

import Swal from "sweetalert2";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

interface Question {
  id: number;
  question: string;
  notes: string;
  points: number;
  questionType: string;
  answer: string;

  options: string[];
  answers: string[];
}


interface QandA {
  question: Question;
  answer: any;
}

@Component({
  selector: 'app-exam',
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, MatIconModule,TranslateModule,CommonModule, HttpClientModule, NavComponent, PathComponent, FooterComponent, ExamDescriptionComponent, QuestionType1Component, QuestionType2Component, QuestionType3Component, QuestionType4Component],
  templateUrl: './exam.component.html',
  styleUrl: './exam.component.scss'
})
export class ExamComponent implements OnInit {
  constructor( private translate: TranslateService,private storageService: StorageServiceService, private http: HttpClient, private route: ActivatedRoute, private router: Router) { }
  questions: QandA[] = []
  infos:any


  ngOnInit(): void {

    this.translate.addLangs(['en', 'fr']);

    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");

      }

    } catch (error) {
      this.translate.setDefaultLang('fr');

    }
    const apiUrl1 = `https://soacwaaslearning.studiolab.fr/elearning/question/getQuestionsByCourse/${this.route.snapshot.paramMap.get('id')}`

    this.http.get(apiUrl1).subscribe((result: any) => {
      this.questions = result.map((el: Question) => {
        if (el.questionType == "multiple answers") {
          return { question: el, answer: [] }
        }
        return { question: el, answer: "" }

      });


    })

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.storageService.getUser().tokenType} ${this.storageService.getUser().accessToken}`
    });
    const apiUrl = ` https://soacwaaslearning.studiolab.fr/elearning/course/getCourceByStudent/${this.route.snapshot.paramMap.get('id')}`
    this.http.get(apiUrl, { headers }).subscribe((resulte: any) => {
      this.infos=resulte
    })

  }

  changeLanguage = (lang: string) => {
    this.translate.setDefaultLang(lang)
    localStorage.setItem("lang", lang)
  }

  getFlag(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "assets/en.png"
      } else {
        return "assets/fr.png"
      }
    } catch (e) {
      return "assets/fr.png"

    }
  }
  getDiffFlag(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "assets/fr.png"
      } else {
        return "assets/en.png"
      }
    } catch (e) {
      return "assets/en.png"

    }
  }
  getDiffLang(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "Francais"
      } else {
        return "English"
      }
    } catch (e) {
      return "English"

    }
  }
  getDiffLangAp(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "fr"
      } else {
        return "en"
      }
    } catch (e) {
      return "en"

    }
  }


  testMultiAnswers(optionsArray: string[], answersArray: string[]) {
    let correctAnswers = 0
    let wrongAnswers = 0
    answersArray.forEach(el => {
      if (optionsArray.indexOf(el) != -1) {
        correctAnswers += 1
      } else {
        wrongAnswers += 1
      }
    })
    return { correctAnswers, wrongAnswers }
  }
  score = 0

  checkScore() {

    this.questions.forEach(el => {
      switch (el.question.questionType) {
        case "writin":
          if (el.question.answer == el.answer) {
            this.score += el.question.points
          }
          break;
        case "single answer":
          if (el.question.answer == el.answer) {
            this.score += el.question.points
          }
          break;
        case "multiple answers":
          let { correctAnswers, wrongAnswers } = this.testMultiAnswers(el.question.answers, el.answer)


          if (correctAnswers == el.question.answers.length && wrongAnswers == 0) {
            this.score += el.question.points
          } else {
            let singleAnswerScore = el.question.points / el.question.answers.length
            let temporaryScore = (singleAnswerScore * correctAnswers) - (singleAnswerScore * wrongAnswers)
            if (temporaryScore > 0) {
              this.score += temporaryScore
            }
          }
          break;
      }
    })
    setTimeout(() => {
      this.infos.examScore = this.score;
      this.infos.passed = (this.score >= this.infos.passingScore);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.storageService.getUser().tokenType} ${this.storageService.getUser().accessToken}`
      });
      const apiUrl = `https://soacwaaslearning.studiolab.fr/elearning/course/setCourseattended`;
      this.http.put(apiUrl, this.infos, { headers }).subscribe((result: any) => {
        
        if (this.infos.passed) {
          // Traduction pour le succès de l'examen
          this.translate.get(['exam_passed_title', 'exam_passed_message']).subscribe(translations => {
            Swal.fire({
              title: translations['exam_passed_title'],
              text: translations['exam_passed_message'],
              icon: 'success'
            });
          });
          this.router.navigateByUrl('/attestation/' + this.infos.idCource);
    
        } else {
          // Traduction pour l'échec de l'examen
          this.translate.get(['exam_failed_title', 'exam_failed_message']).subscribe(translations => {
            Swal.fire({
              title: translations['exam_failed_title'],
              text: translations['exam_failed_message'],
              icon: 'error'
            });
          });
          this.router.navigateByUrl('/dashboard-student');
    
        }
        console.log(result);
    
      });
    },
     1000);


  }

  navigateToAbout() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['attestation'])
    );
    window.open(url, '_blank');
    this.router.navigate(['dashboard-student-course']);

  }
}
