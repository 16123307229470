import { Component, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-faq-list',
  standalone: true,
  imports: [MatExpansionModule],
  templateUrl: './faq-list.component.html',
  styleUrl: './faq-list.component.scss'
})
export class FaqListComponent {
  @Input() faqList:any[]=[]

}
