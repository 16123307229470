import { Component , OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule , MatDialogRef } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-yes-or-no',
  standalone: true,
  imports: [TranslateModule,MatButtonModule,MatDialogModule ],
  templateUrl: './yes-or-no.component.html',
  styleUrl: './yes-or-no.component.scss'
})
export class YesOrNoComponent implements OnInit {
  constructor(private translate: TranslateService,public dialogRef: MatDialogRef<YesOrNoComponent>) {}

  ngOnInit(): void {
    this.translate.addLangs(['en', 'fr']);

    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");
      }else{
        this.translate.setDefaultLang('fr');
      }
    } catch (error) {
      this.translate.setDefaultLang('fr');
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
