

<mat-drawer-container style="width: 100%; height: 100%;" autosize>
  <mat-drawer #drawer mode="side">
    <app-sidebar-admin></app-sidebar-admin>
  </mat-drawer>

  <div>
    <app-navbar-leasson (sidenav)="drawer.toggle()"></app-navbar-leasson>
    <div class="dashboard-body">
      <router-outlet></router-outlet>

    </div>

  </div>







  <script src='http://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js'></script>



  <script src="js/index.js"></script>




</mat-drawer-container>