
import Swal from 'sweetalert2';
import { Router, RouterModule } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../Services/auth.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';





@Component({
  selector: 'app-registration',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule,
    MatButtonModule, MatMenuModule, MatIconModule,
  ],
  templateUrl: './registration.component.html',
  styleUrl: './registration.component.scss'
})
export class RegistrationComponent {
  form: any = {
    username: null,
    email: null,
    password: null
  };
  profileImageFile!: File ;
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';

  constructor(private _snackBar: MatSnackBar, private authService: AuthService, private router: Router, private translate: TranslateService) {



   }
  openSnackBar(message: string) {
    this._snackBar.open(message, "", {
      duration: 3000
    });

  }
  ngOnInit(): void {
    this.translate.addLangs(['en', 'fr']);

    try {
      if (localStorage.getItem("lang")) {
        this.translate.setDefaultLang(localStorage.getItem("lang") || "");
      }else{
        this.translate.setDefaultLang('fr');
      }

    } catch (error) {


    }
    // Initialisation ou autres configurations si nécessaires
  }

  changeLanguage = (lang: string) => {
    this.translate.setDefaultLang(lang)
    localStorage.setItem("lang", lang)
  }

  getFlag(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "assets/en.png"
      } else {
        return "assets/fr.png"
      }
    } catch (e) {
      return "assets/fr.png"

    }
  }
  getDiffFlag(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "assets/fr.png"
      } else {
        return "assets/en.png"
      }
    } catch (e) {
      return "assets/en.png"

    }
  }
  getDiffLang(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "Francais"
      } else {
        return "English"
      }
    } catch (e) {
      return "English"

    }
  }
  getDiffLangAp(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "fr"
      } else {
        return "en"
      }
    } catch (e) {
      return "en"

    }
  }

  // Méthode pour gérer la sélection d'un fichier d'image de profil
  onProfileImageFileChange(event: any): void {
    if (event.target.files && event.target.files.length > 0) {
      this.profileImageFile = event.target.files[0];
    }
  }



  validate() {
    const { username, email, password } = this.form;
  
    if (!this.isUsernameValid(username)) {
      const errorMessage = this.translate.instant('invalid_username'); // Utilisation de la clé de traduction
      this.openSnackBar(errorMessage);
      return false;
    }
  
    if (!this.isEmailValid(email)) {
      const errorMessage = this.translate.instant('invalid_email'); // Utilisation de la clé de traduction
      this.openSnackBar(errorMessage);
      return false;
    }
  
    if (!this.isPasswordValid(password)) {
      const errorMessage = this.translate.instant('invalid_password'); // Utilisation de la clé de traduction
      this.openSnackBar(errorMessage);
      return false;
    }
  
    if (!this.profileImageFile) {
      const errorMessage = this.translate.instant('please_select_an_image'); // Utilisation de la clé de traduction
      this.openSnackBar(errorMessage);
      return false;
    }
  
    return true;
  }
  

  isUsernameValid(username: string) {
    // Example validation: username must be at least 3 characters long
    return typeof username === 'string' && username.length >= 3;
  }

  isEmailValid(email: string) {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  isPasswordValid(password: string) {
    // Example validation: password must be at least 6 characters long
    return typeof password === 'string' && password.length >= 6;
  }

  // Méthode de soumission de formulaire d'inscription
  onSubmit(): void {
    const { username, email, password } = this.form;
  
    if (this.validate()) {
      // Appelez la méthode register de AuthService avec les données de formulaire et l'image de profil
      this.authService.register(username, email, password, this.profileImageFile).subscribe({
        next: data => {
          this.isSuccessful = true;
          this.isSignUpFailed = false;
  
          // Afficher une alerte de succès à l'aide de Swal
          this.translate.get([
            'register_success_title',
            'register_success_message'
          ]).subscribe(translations => {
            Swal.fire({
              title: translations['register_success_title'],
              text: translations['register_success_message'],
              icon: 'success'
            });
          });
  
          // Rediriger vers la page de connexion après une inscription réussie
          this.router.navigate(['/login']);
        },
        error: err => {
          // Gérez les erreurs d'inscription
          console.error('Registration error:', err.error.message);
  
          this.translate.get([
            'registration_error_message',
            'registration_error_default'
          ]).subscribe(translations => {
            this.openSnackBar(translations['registration_error_message'] || translations['registration_error_default']);
          });
  
          this.errorMessage = err.error?.message || 'An error occurred during registration.';
          this.isSignUpFailed = true;
        }
      });
    }
  }
  
}
