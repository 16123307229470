<header class="pb-4" id="id-header">
    <div class="video-container">
        <!-- <video autoplay muted loop id="bg-video">
            <source src="assets/WhatsApp Video 2024-06-07 at 11.41.45.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video> -->
        <div class="carousel-container">
            <div class="carousel-slide" [style.transform]="getTransform()" [style.transition]="transition">

                @for(image of images ; track image){
                    <img  [src]="image" alt="Carousel Image">
                }
            </div>
          </div>
    </div>
    <div class="p-4">
        <div class="row">
            <div class="col-md-8 col-12 header-left-side">
                <h1 >
                    {{ 'Welcome_to_the_soac_e_learning_platform' | translate }}
                </h1>



                <p >
                    {{ 'Our_courses_are_also_available_online' | translate }} <br>
                    {{ 'About_semsoc_e_learning_platform' | translate }}
                </p>
                <div class="d-flex justify-content-between align-items-baseline m-4">
                    <button (click)="goToHome()" class="btn">
                        {{ 'View_courses' | translate }}
                    </button>
                </div>
            </div>
            <div class="col-md-2 col-12 logo-container">
                <img class="logo-img" src="assets/SEMSOAC (1).png">
            </div>
        </div>
    </div>
</header>