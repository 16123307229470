import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar-student',
  standalone: true,
  imports: [TranslateModule,CommonModule ,MatSidenavModule],
  templateUrl: './sidebar-student.component.html',
  styleUrl: './sidebar-student.component.scss'
})
export class SidebarStudentComponent {
  goToHome() {
    this.router.navigateByUrl('/');
  }
  constructor(private router: Router,private translate: TranslateService) { }
  getActiveClass(path:string){
    return this.router.url==path
  }

  ngOnInit(): void {
    this.translate.addLangs(['en', 'fr']);
    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");
      }else{
        this.translate.setDefaultLang('fr');
      }
    } catch (error) {
      this.translate.setDefaultLang('fr');
    }
  }
  navigateToAbout2() {
    this.router.navigate(['dashboard-student']);
  }
  navigateToAbout1() {
    this.router.navigate(['dashboard-student/cources']);
  }
  @Output() toggleSidenav = new EventEmitter<void>();
  showFiller = false;
}
