import { Component } from '@angular/core';
import {Router, RouterModule} from '@angular/router';
import {CommonModule} from "@angular/common";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import Swal from "sweetalert2";
import {UserService} from "../../Services/user.service";
import {AuthService} from "../../Services/auth.service";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-new-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule,
    MatButtonModule, MatMenuModule, MatIconModule
  ],
  templateUrl: './new-password.component.html',
  styleUrl: './new-password.component.scss'
})
export class NewPasswordComponent {

  navigateToAbout() {
    this.router.navigate(['/password-reset-seccess']);
  }
  resetpassword!:FormGroup ;
  constructor(private fb:FormBuilder ,private userservice:UserService , private authservice:AuthService , private router:Router,  private translate: TranslateService) {



  }
  ngOnInit() {
    this.resetpassword = this.fb.group({
      token:[''] ,
      password :['']
    })
    if((window.location.href.split("?token=")[1])){
      this.resetpassword.patchValue({token:window.location.href.split("?token=")[1]})
    }
    this.translate.addLangs(['en', 'fr']);

    try {
      if (localStorage.getItem("lang")) {
        this.translate.setDefaultLang(localStorage.getItem("lang") || "");
      }else{
        this.translate.setDefaultLang('fr');
      }

    } catch (error) {


    }

  }
  changeLanguage = (lang: string) => {
    this.translate.setDefaultLang(lang)
    localStorage.setItem("lang", lang)
  }

  getFlag(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "assets/en.png"
      } else {
        return "assets/fr.png"
      }
    } catch (e) {
      return "assets/fr.png"

    }
  }
  getDiffFlag(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "assets/fr.png"
      } else {
        return "assets/en.png"
      }
    } catch (e) {
      return "assets/en.png"

    }
  }
  getDiffLang(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "Francais"
      } else {
        return "English"
      }
    } catch (e) {
      return "English"

    }
  }
  getDiffLangAp(): string {
    try {
      if (localStorage.getItem("lang") == "en") {
        return "fr"
      } else {
        return "en"
      }
    } catch (e) {
      return "en"

    }
  }
  onSubmit() {
    this.authservice.resetPassword(this.resetpassword.value.token, this.resetpassword.value.password).subscribe({
      next: data => {
        this.translate.get([
          'reset_success_title',
          'reset_success_message'
        ]).subscribe(translations => {
          Swal.fire({
            title: translations['reset_success_title'],
            text: translations['reset_success_message'],
            icon: 'success'
          });
        });
        this.router.navigateByUrl('/password-reset-success');
      },
      error: err => {
        this.translate.get([
          'reset_failure_title',
          'reset_failure_message'
        ]).subscribe(translations => {
          Swal.fire({
            title: translations['reset_failure_title'],
            text: translations['reset_failure_message'],
            icon: 'error'
          });
        });
      }
    });
  }
  





}
