import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageServiceService } from './storage.service.service';

@Injectable({
  providedIn: 'root'
})
export class GuardGuard implements CanActivate {
  isLoggedIn = false;
  constructor(private router:Router,
    private storageService: StorageServiceService,
  ){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.storageService.isLoggedIn()) {
        this.isLoggedIn = true;

         console.log("valider");
         return true ;
       }
       else
       {
        alert("access denied");

          this.router.navigateByUrl("login");
          return false;
       }
  }


}
