<nav>


    <button type="button" mat-button (click)="toggle()">
        <mat-icon class="custom-icon">{{ isIconToggled ? 'close' : 'menu' }}</mat-icon>
    </button>

    <button [hidden]="isLoged" type="button" class="btn btn-outline-light m-4">LOGIN</button>
    <div class="pp">

        <span class="d-flex">
            <div class="d-flex">
                @if(profileImageUrl){
                <img class="avatar" [src]="profileImageUrl" alt="{{'AVATAR' | translate}}">
                }
                <div>
                    <p class="avatar-name">{{ username }}</p>
                    <p class="avatar-job">{{ roles }}</p>
                </div>
                <button mat-icon-button [matMenuTriggerFor]="menu" style="color: #32ab3cc3;">
                    <mat-icon>expand_more</mat-icon>
                </button>
            </div>

            <mat-menu #menu="matMenu">
                <button (click)="navigateToAbout()" mat-menu-item>
                    <mat-icon>dialpad</mat-icon>
                    <span>{{'DASHBOARD' | translate}}</span>
                </button>

                <button (click)="navigateToCources()" mat-menu-item>
                    <mat-icon>dialpad</mat-icon>
                    <span>{{'Cources' | translate}}</span>
                </button>
                <button (click)="navigateToProfile()" mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>{{'PROFILE' | translate}}</span>
                </button>
                <button mat-menu-item (click)="logout()">
                    <mat-icon>logout</mat-icon>
                    <span>{{'LOGOUT' | translate}}</span>

                </button>
            </mat-menu>
        </span>

        <a>
            <span class="d-flex">
                <div class="d-flex px-md-5">

                    <button mat-icon-button [matMenuTriggerFor]="menulang">
                        <img style="width: 30px; height: 30px;" [src]="getFlag()" alt="{{'AVATAR' | translate}}">
                    </button>
                    <button mat-icon-button [matMenuTriggerFor]="menulang" style="color: #32ab3cc3;">
                        <mat-icon>expand_more</mat-icon>
                    </button>
                </div>

                <mat-menu #menulang="matMenu">
                    <button mat-menu-item (click)="changeLanguage(getDiffLangAp())">
                        <span> <img style="width: 35px; height: 35px;" [src]="getDiffFlag()"> {{getDiffLang()}}</span>
                    </button>
                </mat-menu>
            </span>
        </a>


    </div>
</nav>