<div class="row">
    <div class="col-1"></div>
    <a class="col-8" href="dashboard-admin/cources" style="padding-bottom: 20px; text-decoration: none;">
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.5 7.99992H17.5M8.33333 3.33325L2.5 7.99992L8.33333 3.33325ZM2.5 7.99992L8.33333 12.6666L2.5 7.99992Z"
                stroke="#282938" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
        {{ 'HOMEPAGE' | translate }}
    </a>
</div>
<div class="container">

    <header class="header">
        <h1>{{ 'HEADER_CREATE_COURSE' | translate }}</h1>
    </header>
    <div class="course-form">
        @if(picture){
        <img [src]="picture">
        }
        <label for="uploadphoto">
            {{ 'LABEL_UPLOAD_PHOTO' | translate }}
            <input class=" col-5 btn btn-primary" style="background-color: #FFC825;" type="file" value="upload picture"
                (change)="uploadImage($event)">
        </label>





        <h5>{{ 'LABEL_UPLOAD_VIDEO' | translate }}</h5>
        <input type="file" (change)="onFileSelected($event)">
        <button (click)="onUpload()" class=" col-12 btn btn-primary" style="background-color: #FFC825;"> {{
            'BUTTON_UPLOAD_VIDEO' | translate }}
        </button>


        @if(isloading) {
        <div class="d-flex justify-content-center">
            <mat-spinner></mat-spinner>
        </div>
        }


        @if(videoUrl){
        <video width="600" controls>
            <source [src]="videoUrl" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        }
        <mat-form-field>
            <mat-label>{{ 'LABEL_NAME' | translate }}</mat-label>
            <input [(ngModel)]="name" matInput>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'LABEL_PASSING_SCORE' | translate }}</mat-label>
            <input type="number" [(ngModel)]="passingScore" matInput>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'LABEL_PRICE' | translate }}</mat-label>
            <input type="number" [(ngModel)]="price" matInput>
        </mat-form-field>
         <mat-form-field>
            <mat-label>{{ 'LABEL_INSTRUCTOR_1' | translate }}</mat-label>
            <input type="text" [(ngModel)]="fourmateur1" matInput>
        </mat-form-field>


        <mat-form-field>
            <mat-label>{{ 'date_formation' | translate }} 1</mat-label>
            <input type="date" [(ngModel)]="somedate" matInput>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'date_formation' | translate }} 2</mat-label>
            <input type="date" [(ngModel)]="somedate2" matInput>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'apprentissage' | translate }}</mat-label>
            <input type="text" [(ngModel)]="apprentissage" matInput>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'mode' | translate }}</mat-label>
            <input type="text" [(ngModel)]="mode" matInput>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'formation Identifier' | translate }}</mat-label>
            <input type="text" [(ngModel)]="formationIdentifier" matInput>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'LABEL_CATEGORY' | translate }}</mat-label>
            <mat-select [(ngModel)]="category">
                <mat-option value="TRAINING FOR TESTING AND ANALYSIS LABORATORIES">{{ 'TRAINING FOR TESTING AND ANALYSIS
                    LABORATORIES' | translate }}</mat-option>
                <mat-option value="TRAINING FOR LABORATORIES">{{ 'TRAINING FOR LABORATORIES' | translate }}</mat-option>
                <mat-option value="TRAINING FOR MEDICAL BIOLOGY LABORATORIES">{{ 'TRAINING FOR MEDICAL BIOLOGY
                    LABORATORIES' | translate }}</mat-option>
                <mat-option value="TRAINING FOR CERTIFICATION BODIES">{{ 'TRAINING FOR CERTIFICATION BODIES' | translate
                    }}</mat-option>
                <mat-option value="TRAINING FOR PROFICIENCY TESTING BODIES">{{ 'TRAINING FOR PROFICIENCY TESTING BODIES'
                    | translate }}</mat-option>
                <mat-option value="TRAINING FOR VERIFICATION AND VALIDATION BODIES">{{ 'TRAINING FOR VERIFICATION AND
                    VALIDATION BODIES' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>{{ 'LABEL_BRIEF_DESCRIPTION' | translate }}</mat-label>
            <textarea matInput [(ngModel)]="briefing" placeholder="Ex. It's a python course..."></textarea>
        </mat-form-field>
        <div>
            <mat-form-field>
                <mat-label>{{ 'LABEL_ADD_CHAPTER' | translate }}</mat-label>
                <input [(ngModel)]="newChapter" matInput>
            </mat-form-field>
            <button (click)="addChapters()" mat-mini-fab color="primary" style="background-color: #FFC825;"
                aria-label="Example icon button with a plus icon">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <mat-chip-set aria-label="Fish selection">
            @for (user of chapters; track user) {
            <div style="position: relative;">
                <mat-chip class="custimChip">{{user}}</mat-chip>

                <button (click)="removeChapter(user)" style="position: absolute; top:-20px; right: -23px;"
                    mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                    <mat-icon>close</mat-icon>
                </button>
            </div>

            } @empty {
            <mat-chip disabled>{{ 'LABEL_NO_CHAPTERS' | translate }}</mat-chip>
            }


        </mat-chip-set>
    </div>

    <mat-tab-group>
        <mat-tab label="{{ 'Objectif' | translate }}">
            <angular-editor [(ngModel)]="description" [config]="config"></angular-editor>
        </mat-tab>
        <mat-tab label="{{ 'Public concerné' | translate }}">
            <angular-editor [(ngModel)]="instructor" [config]="config"></angular-editor>
        </mat-tab>
        <mat-tab label="{{ 'Formateur ' | translate }}">
            <angular-editor [(ngModel)]="syllabus" [config]="config"></angular-editor>
        </mat-tab>
        <mat-tab label="{{ 'Prérequis ' | translate }}">
            <angular-editor [(ngModel)]="precondition" [config]="config"></angular-editor>
        </mat-tab>
        <mat-tab label="{{ 'Méthode d’apprentissage ' | translate }}">
            <angular-editor [(ngModel)]="teachingMethod" [config]="config"></angular-editor>
        </mat-tab>
        <mat-tab label="{{ 'Renseignements et inscriptions ' | translate }}">
            <angular-editor [(ngModel)]="testimonials" [config]="config"></angular-editor>
        </mat-tab>
    </mat-tab-group>




    <div class="row p-4 ">
        <button class=" col-12 btn btn-primary" style="background-color: #FFC825;" (click)="createCourse()">{{
            'BUTTON_CREATE' | translate }}</button>

    </div>
</div>