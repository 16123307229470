<div class="container">
 



    <p>
        {{ 'free_access_text' | translate }}
    </p>
    <button (click)="subscribeCource()">
        {{ 'take_course_button' | translate }} 
    </button>

    <div>
        <div class="p-md-4 role_number_1">
            <p class="first_p">
                {{'cours de' | translate }} {{formationIdentifier}}
            </p>
            @if(!isLoggedIn){
            <a href="/registration" class="px-md-4 fist_button">
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="WHITE"
                    class="bi bi-arrow-right-circle-fill" viewBox="0 0 32 16">
                    <path
                        d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
                <p class="second_p">{{'CREER VOTRE COMPTE GRATUIT ET INSCRIVEZ-VOUS' | translate}} </p>

            </a>
            }
      
        </div>
        <div class="p-md-4"
            style="border: 1px solid lightgray;background-color: #f2f8ff;    display: flex; align-items: baseline; justify-content: space-between;">
            <div style=" display: flex; align-items: baseline;">
                <span
                    style="background-color: white;border-radius: 25px;padding: 0px 7px 5px 7px;border: 1px solid lightgray;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0074fd" class="bi bi-calendar"
                        viewBox="0 0 16 16">
                        <path
                            d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                    </svg>
                </span>
                <p class="px-4"> {{somedate | date}} - {{somedate2 | date}}</p>
            </div>
            <span (click)="toggleDisplay()" class="mx-md-5"
                style="cursor: pointer; background-color: white;border-radius: 25px;padding: 0px 7px 5px 7px;border: 1px solid lightgray;">

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0074fd" class="bi bi-arrow-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"/>
                  </svg>
            </span>

        </div>
        @if(displayContent){
            <div class="p-md-4"
            style="border: 1px solid lightgray;background-color: white;    display: flex; align-items: baseline;">
            <span
                style="background-color: white;border-radius: 25px;padding: 0px 7px 5px 7px;border: 1px solid lightgray;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0074fd"
                    class="bi bi-currency-dollar" viewBox="0 0 16 16">
                    <path
                        d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z" />
                </svg>
            </span>
            <p class="px-4">{{price}} FCFA</p>
        </div>
        <div class="p-md-4"
            style="border: 1px solid lightgray;background-color: white;    display: flex; align-items: baseline;">
            <span
                style="background-color: white;border-radius: 25px;padding: 0px 7px 5px 7px;border: 1px solid lightgray;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0074fd" class="bi bi-keyboard"
                    viewBox="0 0 16 16">
                    <path
                        d="M14 5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1zM2 4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z" />
                    <path
                        d="M13 10.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25zm0-2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25zm-5 0A.25.25 0 0 1 8.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 8 8.75zm2 0a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25zm1 2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25zm-5-2A.25.25 0 0 1 6.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 6 8.75zm-2 0A.25.25 0 0 1 4.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 4 8.75zm-2 0A.25.25 0 0 1 2.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 2 8.75zm11-2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25zm-2 0a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25zm-2 0A.25.25 0 0 1 9.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 9 6.75zm-2 0A.25.25 0 0 1 7.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 7 6.75zm-2 0A.25.25 0 0 1 5.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 5 6.75zm-3 0A.25.25 0 0 1 2.25 6h1.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-1.5A.25.25 0 0 1 2 6.75zm0 4a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25zm2 0a.25.25 0 0 1 .25-.25h5.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-5.5a.25.25 0 0 1-.25-.25z" />
                </svg>
            </span>
            <p class="px-4"> {{apprentissage}} </p>
        </div>

        <div class="p-md-4" style="border: 1px solid lightgray;background-color: white;   ">
            <div style=" display: flex; align-items: baseline;">
                <span
                    style="background-color: white;border-radius: 25px;padding: 0px 7px 5px 7px;border: 1px solid lightgray;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0074fd" class="bi bi-info-lg"
                        viewBox="0 0 16 16">
                        <path
                            d="m9.708 6.075-3.024.379-.108.502.595.108c.387.093.464.232.38.619l-.975 4.577c-.255 1.183.14 1.74 1.067 1.74.72 0 1.554-.332 1.933-.789l.116-.549c-.263.232-.65.325-.905.325-.363 0-.494-.255-.402-.704zm.091-2.755a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0" />
                    </svg>
                </span>
                <p class="px-4"> {{'mode de formation' | translate}} </p>
            </div>
            <p style="padding-left: 55px; text-align: left;">
                {{mode}}
            </p>

        </div>
        }


    </div>
</div>
<div class="ab">

    <h1>
        {{ 'dates_indicative_note' | translate }} <br>
    </h1>
    <h1>
        {{ 'subsidized_amounts_note' | translate }} <br>
    </h1>
    <h1>
        {{ 'shipping_payment_note' | translate }} <br>
    </h1>
    <h1>
        {{ 'online_sessions_note' | translate }} <br>
    </h1>
    <h1>
        {{ 'in_person_sessions_note' | translate }} <br>
    </h1>

    <h1>
        {{ 'cancellation_policy_note_1' | translate }} <br>
    </h1>
    <h1>
        {{ 'cancellation_policy_note_2' | translate }} <br>
    </h1>

    <h1>
        {{ 'replacement_delegates_note' | translate }} <br>
    </h1>




</div>

<p></p>
<p></p>

<p></p>

<p></p>

<div>
    
</div>