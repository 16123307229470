<div class="student-charts-body">
    <div class="d-flex">
         <div class="col-md-6">
            <ngx-charts-bar-vertical [scheme]="colorScheme" [results]="data1" [gradient]="gradient" [xAxis]="showXAxis"
                [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel">
            </ngx-charts-bar-vertical>
        </div>
        <div class="col-md-6">
            <ngx-charts-pie-chart [scheme]="colorScheme" [results]="data" [gradient]="gradient"
                [legend]="showLegend" [labels]="showLabels" [doughnut]="isDoughnut">
            </ngx-charts-pie-chart>
        </div>

    </div>

</div>