import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-leason-body',
  standalone: true,
  imports: [PdfViewerModule,   TranslateModule],
  templateUrl: './leason-body.component.html',
  styleUrl: './leason-body.component.scss'
})
export class LeasonBodyComponent implements OnChanges {

  @Input() showleasson!: boolean
  @Input() currentLeasson: string = ""
  @Input() lastLeasson!: boolean
  @Input() firstLeasson!: boolean
  @Input() nextPage!: any
  @Input() previousPage!: any
  @Input() finishButton!: any


  showvideo:boolean=true
  showpdf:boolean=false
  showButtons:boolean=true

  currentLeassonBody: any

  constructor(private http: HttpClient, private router: Router,private translate: TranslateService) { }
  navigateToAbout() {
    // this.router.navigate(['/cources/exam/515151']);
    this.finishButton()
  }

  ngOnInit(): void {
    this.translate.addLangs(['en', 'fr']);

    try {
      if (localStorage.getItem("lang")) {
        this.translate.setDefaultLang(localStorage.getItem("lang") || "");
      }else{
        this.translate.setDefaultLang('fr');
      }

    } catch (error) {


    }
    // Initialisation ou autres configurations si nécessaires
  }

  changeLanguage = (lang: string) => {
    this.translate.setDefaultLang(lang)
    localStorage.setItem("lang", lang)
  }

  zoom = 1

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentLeasson'].currentValue) {
      this.oncurrentLeassonChange(changes['currentLeasson'].currentValue);
    }
  }

  fnShowPdf(){
    this.showvideo=false
    this.showpdf=true
  }

  fnShowVideo(){
    this.showvideo=true
    this.showpdf=false
  }

  oncurrentLeassonChange(newValue: boolean) {
    const apiUrl1 = ` https://soacwaaslearning.studiolab.fr/elearning/leasson/getLeasson/${newValue}`
    this.http.get(apiUrl1).subscribe((result: any) => {

      this.currentLeassonBody = result

      if(this.currentLeassonBody.pdfUrl && this.currentLeassonBody.videoUrl ){
        this.showButtons=true
      } else{
        this.showButtons=false
      }
    })
  }

}
