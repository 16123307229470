import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { YesOrNoComponent } from '../../compoments/commun/yes-or-no/yes-or-no.component';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';


export interface Faq {
  id: number;
  questionFr: string;
  answerFr: string;
  questionEn: string;
  answerEn: string;
}

@Component({
  selector: 'app-edit-general-faq',
  standalone: true,
  imports: [MatProgressSpinnerModule, TranslateModule, YesOrNoComponent, MatButtonModule, MatChipsModule, MatIconModule, HttpClientModule, CommonModule, FormsModule, MatSelectModule, MatInputModule, MatFormFieldModule],
  templateUrl: './edit-general-faq.component.html',
  styleUrl: './edit-general-faq.component.scss'
})
export class EditGeneralFaqComponent {
  constructor(private translate: TranslateService, private _snackBar: MatSnackBar, public dialog: MatDialog, private http: HttpClient, private route: ActivatedRoute, private router: Router) { }
  name = ""
  questions: Faq[] = []

  showAddform = false
  addState = true

  newQuestionfr = ""

  newAnswerfr = ""

  newQuestionen = ""

  newAnsweren = ""


  updatedId: number = -1




  ngOnInit(): void {

    this.translate.addLangs(['en', 'fr']);

    try {
      if (localStorage.getItem("lang")) {
        this.translate.setDefaultLang(localStorage.getItem("lang") || "");
      } else {
        this.translate.setDefaultLang('fr');
      }
    } catch (error) {
      this.translate.setDefaultLang('fr');
    }


    this.getQuestions()
  }

  getQuestions() {
    const apiUrl1 = `https://soacwaaslearning.studiolab.fr/elearning/generalfaq/getAll`

    this.http.get(apiUrl1).subscribe((result: any) => {
      this.questions = result;
    })
  }


  changeLanguage = (lang: string) => {
    this.translate.setDefaultLang(lang)
  }




  addNewQuestion() {
    this.showAddform = true
    this.newQuestionfr = ""
    this.newAnswerfr = ""

    this.newQuestionen = ""

    this.newAnsweren = ""

  }
  openDialog(idQuestion: number): void {
    const dialogRef = this.dialog.open(YesOrNoComponent);

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.deleteQuestion(idQuestion)
      } else {
        // alert("no")
      }
    });
  }

  saveQuestion() {
    const apiUrl = `https://soacwaaslearning.studiolab.fr/elearning/generalfaq/update`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    
    let q = {
      "id": this.updatedId,
      "questionFr": this.newQuestionfr,
      "answerFr": this.newAnswerfr,
      "questionEn": this.newQuestionen,
      "answerEn": this.newAnsweren
    };
  
    this.http.put(apiUrl, q, { headers }).subscribe(result => {
      this.translate.get('FAQ_UPDATED_SUCCESS').subscribe((translatedMessage: string) => {
        this.openSnackBar(translatedMessage);
      });
  
      setTimeout(() => {
        this.getQuestions();
        this.showAddform = false;
      }, 500);
    });
  }

  createNewQuestion() {
    const apiUrl = `https://soacwaaslearning.studiolab.fr/elearning/generalfaq/create`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let q = {
      "questionFr": this.newQuestionfr,
      "answerFr": this.newAnswerfr,
      "questionEn": this.newQuestionen,
      "answerEn": this.newAnsweren
    };
  
    this.http.post(apiUrl, q, { headers }).subscribe(result => {
      this.translate.get('FAQ_CREATED_SUCCESS').subscribe((translatedMessage: string) => {
        this.openSnackBar(translatedMessage);
      });
      setTimeout(() => {
        this.getQuestions();
        this.showAddform = false;
      }, 500);
    });
  }
  

  select2edit(idQuestion: number) {
    this.showAddform = true
    this.addState = false
    this.updatedId = this.questions[idQuestion].id
    this.newQuestionfr = this.questions[idQuestion].questionFr
    this.newAnswerfr = this.questions[idQuestion].answerFr
    this.newQuestionen = this.questions[idQuestion].questionEn
    this.newAnsweren = this.questions[idQuestion].answerEn

  }

  deleteQuestion(idQuestion: number) {
    const apiUrl = `https://soacwaaslearning.studiolab.fr/elearning/generalfaq/delete/${idQuestion}`;
    
    this.http.delete(apiUrl).subscribe(result => {
      this.translate.get('FAQ_DELETED_SUCCESS').subscribe((translatedMessage: string) => {
        this.openSnackBar(translatedMessage);
      });
  
      setTimeout(() => {
        this.getQuestions();
      }, 500);
    });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, "", {
      duration: 3000
    });
  }
}
