<div class="path p-md-5">
    <div>
        <span>
            <a href="">{{ 'HOME' | translate }}</a>          {{ currentPath}} 
        </span>
    
        
    </div>
    {{name}}    
</div>
