import { CommonModule } from '@angular/common';
import { Component, Input , OnInit } from '@angular/core';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-items',
  standalone: true,
  imports: [TranslateModule,MatButtonToggleModule,MatIconModule,CommonModule , MatProgressSpinnerModule],
  templateUrl: './items.component.html',
  styleUrl: './items.component.scss'
})
export class ItemsComponent implements OnInit {
  @Input() cources: any;

  constructor(private translate: TranslateService,private router: Router) { }

  ngOnInit(): void {

    this.translate.addLangs(['en', 'fr']);

    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");
      }else{
        this.translate.setDefaultLang('fr');
      }
    } catch (error) {
      this.translate.setDefaultLang('fr');
    }
  }

  showCards:boolean=false

  changeShowCard(b:boolean){
    this.showCards=b
  }

  navigateToAbout(id: string): void {
    // this.router.navigate([`/cources/detail/${id}`]).then(() => {
    //   window.scrollTo(0, 0); // Fait défiler vers le haut de la nouvelle page après la navigation
    // });
    window.location.href=`/cources/detail/${id}`
  }

}
