import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../Services/auth.service';
import { UserService } from '../../../Services/user.service';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { StorageServiceService } from '../../../storage.service.service';

@Component({
  selector: 'app-admin-statistics',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './admin-statistics.component.html',
  styleUrl: './admin-statistics.component.scss'
})
export class AdminStatisticsComponent  implements OnInit  {

  constructor(private storageService: StorageServiceService,private translate: TranslateService,private userService: UserService,private auth: AuthService,private http: HttpClient){}
  totalUsers: number = 0;
  totalAssistants: number = 0;
  // Property to hold the total number of assistants
  totalStudents: number = 0;
  totalCours:number=0;
  totalCoursAttended:number=0;

  isAssistance=false

  ngOnInit(): void {
    try {
      this.isAssistance=this.storageService.getUser().roles[0]=="ROLE_ASSISTANT"

    } catch (error) {

    }

    this.translate.addLangs(['en', 'fr']);

    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");
      }
    } catch (error) {
      this.translate.setDefaultLang('fr');
    }

        this.fetchTotalUser();
        this.fetchTotalUser1();

        this.fetchTotalAssistants(); // Fetch the total number of assistants on init
        this.fetchTotalStudents(); // Fetch the total number of students on init

  }

  fetchTotalUsers(): void {
    this.userService.getTotalUsers().subscribe(
      (count: number) => {
        this.totalUsers = count;
      },
      (error) => {
        console.error('Error fetching total users', error);
      }
    );
  }
  changeLanguage = (lang: string) => {
    this.translate.setDefaultLang(lang)
    localStorage.setItem("lang", lang)
  }

  fetchTotalUser(): void {
    console.log('count ')

    this.http.get<number>('https://soacwaaslearning.studiolab.fr/elearning/course/count').subscribe(
      (count: any) => {
        this.totalCours = count;
      },
      (error) => {
        console.error('Error fetching total totalCours', error);
      }
    );
  }

  fetchTotalUser1(): void {
    console.log('count ')

    this.http.get<number>('https://soacwaaslearning.studiolab.fr/elearning/course/count2').subscribe(
      (count: any) => {
        this.totalCoursAttended = count;
      },
      (error) => {
        console.error('Error fetching total totalCoursAttended', error);
      }
    );
  }

  fetchTotalAssistants(): void {
    this.auth.getTotalAssistants().subscribe(
      (response: any) => {
        const message = response.message;
        const totalAssistants = parseInt(message.replace(/^\D+/g, '')); // Extract the number from the message
        if (!isNaN(totalAssistants)) {
          this.totalAssistants = totalAssistants;
        } else {
          console.error('Error parsing total assistants', message);
        }
      },
      (error) => {
        console.error('Error fetching total assistants', error);
      }
    );
  }

  fetchTotalStudents(): void {
    this.auth.getTotalStudents().subscribe(
      (response: any) => {
        const message = response.message;
        const totalStudents = parseInt(message.replace(/^\D+/g, '')); // Extract the number from the message
        if (!isNaN(totalStudents)) {
          this.totalStudents = totalStudents;
        } else {
          console.error('Error parsing total assistants', message);
        }
      },
      (error) => {
        console.error('Error fetching total assistants', error);
      }
    );
  }


}
