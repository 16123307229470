<footer>
    <div class="row">
        <div class="col-md-5">
            <img class="officialLogo" width="100%"  src="assets/SEMSOAC E LEARNING.png">
            <h2>
                {{'Interactive_elearning' | translate}}
            </h2>
            <div class="yellow-banner">
                <div>
                    <p class="title">
                        {{'Email' | translate}}
                    </p>
                    <p class="content">
                        info&#64;soacwaas.org
                    </p>
                </div>
                <div>
                    <p class="title">
                        {{'Telephone' | translate}}
                    </p>
                    <p class="content">
                        +225 07 88 72 08 17 / 07 88 72 68 00
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class=" d-flex justify-content-between">
                <div class="menu">
                    <p class="yellow-title">
                        {{'Social_media' | translate}}
                    </p>

                    <a class="item mt-2" href="https://twitter.com/soacwaas">
                        {{'Twitter' | translate}}
                    </a>
                    <a class="item mt-2" href="https://www.linkedin.com/company/soac-waas-systeme-ouest-africain-d-accreditation">
                        {{'Linkedin' | translate}}
                    </a>
                    <a class="item mt-2" href="https://web.facebook.com/WAASOAC">
                        {{'Facebook' | translate}}
                    </a>
                </div>

                <div class="menu">
                    <p class="yellow-title">
                        {{'PROGRAM' | translate}}
                    </p>
                    <a class="item mt-2" href="">
                        {{'MERDEKA BELAJAR' | translate}}
                    </a>
                    <a class="item mt-2" href="">
                        {{'FINTERPRENEUR' | translate}}
                    </a>
                </div>

                <div class="menu">
                    <p class="yellow-title">
                        {{'SUPPORT' | translate}}
                    </p>
                    <a class="item mt-2" href="">
                        {{'ABOUT US' | translate}}
                    </a>
                    <a class="item mt-2" href="">
                        {{'TERMS' | translate}}
                    </a>
                    <a class="item mt-2" href="">
                        {{'PRIVACY POLICY' | translate}}
                    </a>
                </div>
            </div>
            <div  class="d-flex justify-content-end">
                <img style="    width: 200px;"  src="assets/SEMSOAC (1).png">

            </div>
        </div>
    </div>
</footer>
<div class="copyright">
    <p>
        COPYRIGHT SOACWAAS 2024
    </p>
    <div>
        <a href="#id-header">
            {{'Home' | translate}}
        </a>
        <a href="#id-obout">
            {{'ABOUT US' | translate}}
        </a>
        <a href="/cources">
            {{'COURSES' | translate}}
        </a>
        <a href="https://www.soacwaas.org/actualites.html">
            {{'Blog' | translate}}
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.33333 4.99998H4.99999C4.55797 4.99998 4.13404 5.17557 3.82148 5.48813C3.50892 5.8007 3.33333 6.22462 3.33333 6.66665V15C3.33333 15.442 3.50892 15.8659 3.82148 16.1785C4.13404 16.4911 4.55797 16.6666 4.99999 16.6666H13.3333C13.7754 16.6666 14.1993 16.4911 14.5118 16.1785C14.8244 15.8659 15 15.442 15 15V11.6666M11.6667 3.33331H16.6667M16.6667 3.33331V8.33331M16.6667 3.33331L8.33333 11.6666" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </a>
    </div>
</div>
