import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [TranslateModule, CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit , OnDestroy  {

  images: string[] = [
    'assets/DSC00440.JPG',
    'assets/IMG_2515.JPG',
    'assets/DSC00373.JPG'
  ];
  currentIndex: number = 0;
  intervalId: any;
  transition: string = 'transform 0.5s ease-in-out';

  constructor(private translate: TranslateService, private router: Router) {}

  startCarousel() {
    this.intervalId = setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
      this.transition = 'transform 0.5s ease-in-out';
    }, 3000); // Change image every 3 seconds
  }

  getTransform() {
    return `translateX(-${this.currentIndex * 300 / this.images.length}%)`;
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }



  ngOnInit(): void {
    this.startCarousel();



    this.translate.addLangs(['en', 'fr']);
    try {
      if (localStorage.getItem("lang")) {
        this.translate.setDefaultLang(localStorage.getItem("lang") || "");
      } else {
        this.translate.setDefaultLang('fr');
      }
    } catch (error) {
      this.translate.setDefaultLang('fr');
    }
  }
  goToHome() {
    this.router.navigateByUrl('/cources');
  }
}
