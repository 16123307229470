<div class="student-cources-progress">
    <div class="courses-table p-md-4">
        <div class="filterbar">
            <h2>
                {{ 'LEARNING_ACTIVITY' | translate }}
            </h2>
            <div class="filterbar-inputs col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'SEARCH' | translate }}</mat-label>
                    <input (keyup)="realtimeSearch($event)" matInput placeholder="Search">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'STATUS' | translate }}</mat-label>
                    <mat-select  (selectionChange)="realtimeCategory($event)"  >
                        @for (food of foods; track food.value) {
                        <mat-option  [value]="food.value">
                            {{ food.viewValue }}
                        </mat-option>
                        }

                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <table class="table table-borderless">
            <thead>
                <tr>
                    <th scope="col" class="col-6">{{ 'INFOS' | translate }}</th>
                    <th scope="col" class="col-3">{{ 'PROGRESS' | translate }}</th>
                    <th scope="col" class="col-3">{{ 'STATUS' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                @for( course of courcesbySearch ; track course){
                <tr>
                    <td class="col-6">
                        <div class="row">
                            <div class="col-md-3">
                                <img [src]="course.photo">
                            </div>
                            <div class="col-md-9">
                                <h3>
                                    {{course.title}}
                                </h3>
                                <p>
                                    {{course.description}}
                                </p>
                            </div>
                        </div>


                    </td>
                    <td class="col-3">
                        <h6>{{course.progress+'%'}}</h6>

                        <div class="progress">
                            <div class="progress-bar bg-warning" role="progressbar" [style.width]="course.progress+'%'"
                                aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </td>
                    <td class="col-3">
                        @if(course.type){
                        <button (click)="navigateToAbout(course.id)">
                            {{ 'CERTIFICATE' | translate }}
                        </button>
                        } @else{
                        <a [href]="'learning/course/'+course.id">
                            {{ 'CONTINUE' | translate }}
                        </a>
                        }

                    </td>
                </tr>
                }




            </tbody>
        </table>
    </div>
</div>