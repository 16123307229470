<div class="row">
  <div class="col-4"></div>
  <a class="col-8" href="dashboard-admin/cources" style="padding-bottom: 20px; text-decoration: none;">
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 7.99992H17.5M8.33333 3.33325L2.5 7.99992L8.33333 3.33325ZM2.5 7.99992L8.33333 12.6666L2.5 7.99992Z"
        stroke="#282938" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    {{ 'HOMEPAGE' | translate }}
  </a>
</div>
<div class="container">
  <h2 class="form-title">{{ 'UPDATE_ASSISTANT' | translate }}</h2>
  <form (ngSubmit)="createCourse()" class="update-form">
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>{{ 'NAME' | translate }}</mat-label>
      <input matInput [(ngModel)]="name" name="name" required>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>{{ 'LAST_NAME' | translate }}</mat-label>
      <input matInput [(ngModel)]="last_name" name="last_name" required>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>{{ 'GENDER' | translate }}</mat-label>
      <mat-select [(ngModel)]="sexe" name="sexe" required>
        <mat-option value="Male">{{ 'MALE' | translate }}</mat-option>
        <mat-option value="Female">{{ 'FEMALE' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>{{ 'USERNAME' | translate }}</mat-label>
      <input matInput [(ngModel)]="username" name="username" required>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>{{ 'EMAIL' | translate }}</mat-label>
      <input matInput type="email" [(ngModel)]="email" name="email" required>
    </mat-form-field>

    <button mat-raised-button color="primary" type="submit" class="submit-button">{{ 'BUTTON_UPDATE' | translate }}</button>
  </form>
  @if(loading){
    <mat-progress-bar mode="indeterminate" ></mat-progress-bar>

  }
  @if(isSuccessful){
    <div  class="alert alert-success">
      {{ 'UPDATE_SUCCESS_MESSAGE' | translate }}
    </div>
  }

  @if(isUpdateFailed){
    <div  class="alert alert-danger">
      {{ errorMessage }}
    </div>
  }
</div>
