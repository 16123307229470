<div class="p-md-5" id="id-obout">
    <div class="row m-4">
        <div class="col-md-7 p-md-4 about-us-content">
            <h2>{{ 'ABOUT_US' | translate }}</h2>
            <p>
                {{ 'Soac_summary_description1' | translate }}
            </p>
            <ul>
                <li>
                    {{ 'Soac_summary_list_1' | translate }}
                </li>
                <li>
                    {{ 'Soac_summary_list_2' | translate }}
                </li>
                <li>
                    {{ 'Soac_summary_list_3' | translate }}
                </li>
            </ul>
            <p>
                {{ 'Soac_summary_description2' | translate }}
            </p>
            <p>
                {{ 'Soac_summary_description3' | translate }}
            </p>
        </div>
        <img src="assets/about.jpg" class="col-md-5">
    </div>
</div>
