import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [FormsModule
    ,    TranslateModule,
    MatButtonModule, MatMenuModule, MatIconModule
  ],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent {
  @Input() searchbyWord !:  (t: string) => void;
  @Input() searchbyCategory !:  (t: string) => void;

  search=""

  onEnter(){

    this.searchbyWord(this.search)

  }

  constructor( private translate: TranslateService) {



  }

  ngOnInit(): void {
    this.translate.addLangs(['en', 'fr']);

    try {
      if(localStorage.getItem("lang")){
        this.translate.setDefaultLang(localStorage.getItem("lang")|| "");

      }

    } catch (error) {
      this.translate.setDefaultLang('fr');

    }}

  changeLanguage=(lang:string)=>{
    this.translate.setDefaultLang(lang)
  }

  selectCat(c:string,e:Event){
    let tlist =document.getElementsByClassName("active")[0] as HTMLElement
    tlist.classList.remove("active")
    this.searchbyCategory(c)
    let t= e.target as HTMLElement
    t.classList.add("active")
  }

}
